import {
  take, call, put, takeLatest, select,
} from 'redux-saga/effects';

import MembershipDetailsActions, {
  MembershipDetailsTypes,
} from 'reducers/membershipDetails';
import { SessionTypes } from 'reducers/session';
import MembershipSubscriptionApi from 'apis/membershipApi/MembershipSubscriptionApi';
import MembershipPurchaseApi from 'apis/membershipApi/MembershipPurchaseApi';

const GENERIC_ERROR_MESSAGE = 'An unknown error happened';

export function* requestGetMembershipDetailsHandler() {
  try {
    const { restoreSessionFinished } = yield select((state) => state.session);

    if (!restoreSessionFinished) {
      yield take(
        (action) => action.type === SessionTypes.RESTORE_SESSION_DONE
          || action.type === SessionTypes.RESTORE_SESSION_ERROR,
      );
    }

    const profile = yield select((state) => state.profile);

    if (profile.membership) {
      const { membership: { subscriptionId, programId } } = profile;
      const membershipDetails = yield call(
        MembershipSubscriptionApi.getMembershipSubscription,
        programId,
        subscriptionId,
      );

      yield put(
        MembershipDetailsActions.getMembershipDetailsDone(membershipDetails),
      );
    } else {
      yield put(MembershipDetailsActions.getMembershipDetailsDone());
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail
      : GENERIC_ERROR_MESSAGE;

    yield put(MembershipDetailsActions.getMembershipDetailsError(errorMessage));
  }
}
export function* requestDeleteMembershipDetailsHandler() {
  try {
    const profile = yield select((state) => state.profile);
    const { membership: { subscriptionId } = {} } = profile;

    if (subscriptionId) {
      yield call(
        MembershipSubscriptionApi.deleteMembershipSubscription,
        subscriptionId,
      );

      yield put(MembershipDetailsActions.deleteMembershipDetailsDone());
      yield requestGetMembershipDetailsHandler();
    } else {
      yield put(MembershipDetailsActions.deleteMembershipDetailsDone());
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail
      : GENERIC_ERROR_MESSAGE;

    yield put(
      MembershipDetailsActions.deleteMembershipDetailsError(errorMessage),
    );
  }
}

export function* requestSetCreditCardMembershipDetailsHandler({ creditCard }) {
  try {
    const profile = yield select((state) => state.profile);
    const { membership: { subscriptionId } = {} } = profile;

    if (subscriptionId && creditCard.id) {
      yield call(
        MembershipPurchaseApi.putMembershipPurchase,
        subscriptionId,
        creditCard.id,
      );

      yield put(MembershipDetailsActions.setMembershipCreditCardDone());
      yield requestGetMembershipDetailsHandler();
    } else {
      yield put(MembershipDetailsActions.setMembershipCreditCardDone());
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail
      : GENERIC_ERROR_MESSAGE;

    yield put(MembershipDetailsActions.setMembershipCreditCardError(errorMessage));
  }
}

export function* requestGetPaymentsHistoryHandler() {
  try {
    const { restoreSessionFinished } = yield select((state) => state.session);

    if (!restoreSessionFinished) {
      yield take(
        (action) => action.type === SessionTypes.RESTORE_SESSION_DONE
          || action.type === SessionTypes.RESTORE_SESSION_ERROR,
      );
    }

    const profile = yield select((state) => state.profile);

    if (profile.membership) {
      const { membership: { programId } } = profile;
      const {
        items: paymentsHistory,
        count: paymentsHistoryCount,
      } = yield call(
        MembershipSubscriptionApi.getMembershipSubscriptionInvoice,
        programId,
      );

      yield put(
        MembershipDetailsActions.getPaymentsHistoryDone(
          paymentsHistory, paymentsHistoryCount,
        ),
      );
    } else {
      yield put(MembershipDetailsActions.getPaymentsHistoryDone());
    }
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail
      : GENERIC_ERROR_MESSAGE;

    yield put(MembershipDetailsActions.getPaymentsHistoryError(errorMessage));
  }
}

export function* requestGetNextPaymentsHistoryHandler() {
  try {
    const profile = yield select((state) => state.profile);
    const { membership: { programId } = {} } = profile;

    const membershipDetails = yield select((state) => state.membershipDetails);
    const { paymentsHistoryPageNumber } = membershipDetails;

    const {
      items: paymentsHistory,
      count: paymentsHistoryCount,
    } = yield call(
      MembershipSubscriptionApi.getMembershipSubscriptionInvoice,
      programId,
      { pageNumber: paymentsHistoryPageNumber + 1 },
    );

    yield put(
      MembershipDetailsActions.getNextPaymentsHistoryPageDone(
        paymentsHistory, paymentsHistoryCount,
      ),
    );
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail
      : GENERIC_ERROR_MESSAGE;

    yield put(MembershipDetailsActions.getNextPaymentsHistoryPageError(errorMessage));
  }
}

function* requestGetMembershipDetailsWatcher() {
  yield takeLatest(
    MembershipDetailsTypes.GET_MEMBERSHIP_DETAILS,
    requestGetMembershipDetailsHandler,
  );
}

function* requestDeleteMembershipDetailsWatcher() {
  yield takeLatest(
    MembershipDetailsTypes.DELETE_MEMBERSHIP_DETAILS,
    requestDeleteMembershipDetailsHandler,
  );
}

function* requestSetCreditCardMembershipDetailsWatcher() {
  yield takeLatest(
    MembershipDetailsTypes.SET_MEMBERSHIP_CREDIT_CARD,
    requestSetCreditCardMembershipDetailsHandler,
  );
}

function* requestGetPaymentsHistoryWatcher() {
  yield takeLatest(
    MembershipDetailsTypes.GET_PAYMENTS_HISTORY,
    requestGetPaymentsHistoryHandler,
  );
}

function* requestGetNextPaymentsHistoryWatcher() {
  yield takeLatest(
    MembershipDetailsTypes.GET_NEXT_PAYMENTS_HISTORY_PAGE,
    requestGetNextPaymentsHistoryHandler,
  );
}

export default [
  requestGetMembershipDetailsWatcher,
  requestDeleteMembershipDetailsWatcher,
  requestSetCreditCardMembershipDetailsWatcher,
  requestGetPaymentsHistoryWatcher,
  requestGetNextPaymentsHistoryWatcher,
];
