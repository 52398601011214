import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import SearchCoursesActions, { SearchCoursesTypes } from 'reducers/searchCourses';
import SearchParamsActions from 'reducers/searchParams';
import CoursesApi from 'apis/supremeGolfApi/CoursesApi';
import DateHelper from 'utils/dateHelper';
import pickBy from 'lodash/pickBy';

function buildSearchParams(params) {
  const {
    courseSectionSlug,
    date,
    foreplayReviewedOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    players: qty,
    holes: numHoles,
    price, time,
    cart: isRiding,
    rate,
    locationRange,
    rateTypes,
  } = params;
  const [minHour, maxHour] = time || [null, null];
  const [minPrice, maxPrice] = price || [null, null];
  const [minRadius, maxRadius] = locationRange || [null, null];

  const searchParams = {
    courseSectionSlug,
    date: date && DateHelper.formatDate(date),
    foreplayReviewedOnly,
    barstoolBestOnly,
    marketingPromotionOnly,
    networkMembershipOnly,
    qty,
    minHour,
    maxHour,
    minPrice,
    maxPrice,
    isRiding,
    numHoles,
    minRadius,
    rateTypes,
  };

  if (maxRadius < 150) searchParams.maxRadius = maxRadius;

  if (rate) {
    searchParams[rate] = true;
  }

  return searchParams;
}

export function* requestSearchCoursesHandler({
  lat, lon, hierarchizedUrl, params,
}) {
  try {
    const searchParams = buildSearchParams(params);
    let coursesList;
    let courseBanners;

    if (hierarchizedUrl) {
      const { courses, banners } = yield call(
        CoursesApi.getCoursesByCityUrlHierarchy, hierarchizedUrl, searchParams,
      );
      coursesList = courses || [];
      courseBanners = banners || [];
    } else {
      const { courses, banners } = yield call(CoursesApi.getCourses, lat, lon, searchParams);
      coursesList = courses;
      courseBanners = banners;
    }

    yield put(SearchCoursesActions.getCoursesDone(coursesList));
    yield put(SearchCoursesActions.setCourseBanners(courseBanners));
  } catch (error) {
    yield put(SearchCoursesActions.getCoursesError(error.message));
  }
}

export function* requestSearchSimilarCoursesHandler({ courseId, params }) {
  try {
    const searchParams = buildSearchParams(params);

    const { course, similarCourses, banners } = yield call(
      CoursesApi.getSimilarCourses, courseId, searchParams,
    );

    yield put(SearchCoursesActions.getSimilarCoursesDone(course, similarCourses));
    yield put(SearchCoursesActions.setCourseBanners(banners));
  } catch (error) {
    yield put(SearchCoursesActions.getSimilarCoursesError(error.message));
  }
}

export function* requestSearchSectionCoursesHandler({ lat, lon, params }) {
  try {
    const searchParams = buildSearchParams(params);
    const {
      courseSection, locationResults, coursesWithoutTeeTimes, banners,
    } = yield call(
      CoursesApi.getSectionCourses, lat, lon, searchParams,
    );

    yield put(SearchParamsActions.setValues({}, pickBy({ locationFilter: { ...courseSection, type: 'Section' } })));
    yield put(SearchCoursesActions.getSectionCoursesDone(
      courseSection,
      [].concat(locationResults, coursesWithoutTeeTimes || []),
    ));
    yield put(SearchCoursesActions.setCourseBanners(banners));
  } catch (error) {
    yield put(SearchCoursesActions.getSectionCoursesError(error.message));
  }
}

function* requestSearchCoursesWatcher() {
  yield takeLatest(
    SearchCoursesTypes.GET_COURSES,
    requestSearchCoursesHandler,
  );
}

function* requestSearchSimilarCoursesWatcher() {
  yield takeLatest(
    SearchCoursesTypes.GET_SIMILAR_COURSES,
    requestSearchSimilarCoursesHandler,
  );
}

function* requestSearchSectionCoursesWatcher() {
  yield takeLatest(
    SearchCoursesTypes.GET_SECTION_COURSES,
    requestSearchSectionCoursesHandler,
  );
}

export default [
  requestSearchCoursesWatcher,
  requestSearchSimilarCoursesWatcher,
  requestSearchSectionCoursesWatcher,
];
