import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import constants from 'utils/constants';
import { features } from 'utils/features';

const { Types, Creators } = createActions({
  getCourses: ['lat', 'lon', 'hierarchizedUrl', 'params'],
  getCoursesDone: ['courses'],
  getCoursesError: ['message'],
  getSimilarCourses: ['courseId', 'params'],
  getSimilarCoursesDone: ['course', 'similarCourses'],
  getSimilarCoursesError: ['message'],
  getNextPage: [],
  getSectionCourses: ['lat', 'lon', 'params'],
  getSectionCoursesDone: ['courseSection', 'courses'],
  getSectionCoursesError: ['message'],
  setViewMode: ['viewMode'],
  setCourseBanners: ['banners'],
  toggleCourseFavorite: ['favoriteCourseId'],
});

export const SearchCoursesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  course: null,
  courses: [],
  similarCourses: [],
  banners: [],
  errorMessage: '',
  hasMore: true,
  isDone: false,
  isError: false,
  isLoading: false,
  pageNumber: 1,
  courseSection: null,
  viewMode: 'list',
});

const sortEntities = (first, second) => {
  if (first < second) return -1;
  if (first > second) return 1;
  return 0;
};

const sortCourses = (courses, sortBy) => [...courses].sort((
  { course: entityA }, { course: entityB },
) => {
  let value;
  switch (sortBy) {
    case 'price-lowest-first':
      value = sortEntities(Number(entityA.minRegularRate), Number(entityB.minRegularRate));
      break;
    case 'name-asc':
      value = sortEntities(entityA.name.toLowerCase(), entityB.name.toLowerCase());
      break;
    case 'name-dsc':
      value = sortEntities(entityB.name.toLowerCase(), entityA.name.toLowerCase());
      break;
    case 'distance':
      value = sortEntities(entityA.distance, entityB.distance);
      break;
    case 'best-match':
      value = sortEntities(entityA.distance, entityB.distance);
      break;
    case 'rating':
      value = sortEntities(entityB.rating.valueRounded, entityA.rating.valueRounded);
      break;
    default:
      value = 0; break;
  }
  return value;
});

const DEFAULT_INTERCALATION_ROWS = 12;
const DEFAULT_BANNER_GROUP = 6;

export const applyFilters = ({ courses, similarCourses, sortBy }, isAnchored) => {
  const coursesList = isAnchored ? similarCourses : courses ?? [];
  const listToFilter = coursesList.filter((entity) => entity.type === 'Course');

  // Trick to keep the Deals always at the default interleaved place.
  const listOfDeals = coursesList.filter((entity) => entity.type === 'Deal');

  const listOfBanners = coursesList.filter((entity) => entity.type === 'Banner');

  let coursesSorted = [];
  let sortedTopTierCourses = [];

  const coursesWithTeeTimes = listToFilter
    .filter((item) => !!item.course?.availability?.forDate);
  const silverTierCourses = listToFilter
    .filter((item) => item.course?.partnerTier === 'silver');
  const coursesWithBookingSiteUrl = listToFilter
    .filter((item) => !!item.course?.bookingSiteUrl && !item.course?.availability?.forDate && item.course?.partnerTier !== 'silver');
  const coursesWithoutBookingSiteUrl = listToFilter
    .filter((item) => !item?.course?.bookingSiteUrl && !item.course?.availability?.forDate && item.course?.partnerTier !== 'silver');

  if (sortBy === 'price-lowest-first') {
    sortedTopTierCourses = [...sortCourses(coursesWithTeeTimes, sortBy), ...sortCourses(silverTierCourses, 'distance')];
  } else {
    sortedTopTierCourses = sortCourses([...coursesWithTeeTimes, ...silverTierCourses], sortBy);
  }

  coursesSorted = [
    ...sortedTopTierCourses,
    ...sortCourses(coursesWithBookingSiteUrl, sortBy === 'price-lowest-first' ? 'distance' : sortBy),
    ...sortCourses(coursesWithoutBookingSiteUrl, sortBy === 'price-lowest-first' ? 'distance' : sortBy),
  ];

  if (features.membership) {
    listOfBanners.forEach((banner, idx) => {
      const indexToInsert = DEFAULT_BANNER_GROUP * idx;

      coursesSorted.splice(indexToInsert, 0, banner);
    });
  }

  listOfDeals.forEach((deal, idx) => {
    const indexToInsert = DEFAULT_INTERCALATION_ROWS + idx * (DEFAULT_INTERCALATION_ROWS + 1);
    coursesSorted.splice(indexToInsert, 0, deal);
  });

  return coursesSorted;
};

const getCourses = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  courseSection: null,
});

const getCoursesDone = (state, { courses }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  courses,
});

const getCoursesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const getSimilarCourses = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  courseSection: null,
});

const getSimilarCoursesDone = (state, { course, similarCourses }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  course,
  similarCourses,
});

const getSimilarCoursesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const getNextPage = (state) => state.merge({
  pageNumber: state.pageNumber + 1,
  hasMore: (state.pageNumber * constants.TIME_SLOT_PAGINATION < (
    state.course ? state.similarCourses.length : state.courses.length
  )),
});

const getSectionCourses = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
});

const getSectionCoursesDone = (state, { courseSection, courses }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  courses,
  courseSection,
});

const getSectionCoursesError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const toggleCourseFavorite = (state, { favoriteCourseId }) => {
  if (state.course && state.course.id === favoriteCourseId) {
    return state.merge({ course: { ...state.course, isFavorite: !state.course.isFavorite } });
  }
  const coursesList = JSON.parse(
    JSON.stringify(state.course ? state.similarCourses : state.courses),
  );
  const course = coursesList.find(
    (c) => c.type === 'Course' && c.course.id === favoriteCourseId,
  );

  course.course.isFavorite = !course.course.isFavorite;

  return state.merge(state.course ? { similarCourses: coursesList } : { courses: coursesList });
};

const setViewMode = (state, { viewMode }) => state.merge({
  viewMode,
});

const setCourseBanners = (state, { banners }) => state.merge({
  banners,
});

export const getPaginatedListSelector = (state, isAnchored) => applyFilters(
  state, isAnchored,
).slice(0, state.pageNumber * constants.TIME_SLOT_PAGINATION);

export const getListLengthSelector = (state, isAnchored) => applyFilters(
  state, isAnchored,
).length;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COURSES]: getCourses,
  [Types.GET_COURSES_DONE]: getCoursesDone,
  [Types.GET_COURSES_ERROR]: getCoursesError,
  [Types.GET_SIMILAR_COURSES]: getSimilarCourses,
  [Types.GET_SIMILAR_COURSES_DONE]: getSimilarCoursesDone,
  [Types.GET_SIMILAR_COURSES_ERROR]: getSimilarCoursesError,
  [Types.GET_NEXT_PAGE]: getNextPage,
  [Types.GET_SECTION_COURSES]: getSectionCourses,
  [Types.GET_SECTION_COURSES_DONE]: getSectionCoursesDone,
  [Types.GET_SECTION_COURSES_ERROR]: getSectionCoursesError,
  [Types.SET_VIEW_MODE]: setViewMode,
  [Types.SET_COURSE_BANNERS]: setCourseBanners,
  [Types.TOGGLE_COURSE_FAVORITE]: toggleCourseFavorite,
});
