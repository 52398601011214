import {
  call, put, select, takeLatest, getContext,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import NetworkMembershipActions, { NetworkMembershipTypes } from 'reducers/networkMembership';
import ProfileActions from 'reducers/profile';
import { goToSignInHandler } from 'sagas/session';
import CourseDetailApi from 'apis/supremeGolfApi/CourseDetailApi';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import NetworkMembership from 'apis/supremeGolfApi/NetworkMembership';
import { NETWORK_MEMBERSHIP_JOIN, NETWORK_MEMBERSHIP_SALES } from 'utils/routes';
import { M } from 'utils/siteStyle';

const sortCourses = (courses) => {
  const temp = [...courses] || [];
  return temp.sort((a, b) => {
    const lowerA = a.fullName?.toLowerCase();
    const lowerB = b.fullName?.toLowerCase();
    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return 0;
  });
};

export function* redirectToNetworkMembershipHandler({ meta }) {
  try {
    const { pathname, search } = yield select((state) => state.router.location);
    // eslint-disable-next-line no-nested-ternary
    const queryParam = meta?.planId ? `planId=${meta.planId}` : meta?.interval ? `interval=${meta.interval}` : '';

    if (meta?.redirectToPurchase) {
      const pathWithQuery = `${NETWORK_MEMBERSHIP_JOIN}${search}${search ? `&${queryParam}` : `?${queryParam}`}`;

      const goToSignIn = yield goToSignInHandler({
        path: M.FOH || M.TWC ? `${NETWORK_MEMBERSHIP_SALES}${search}` : pathWithQuery,
        newWindow: false,
        trackEvent: null,
      });
      if (goToSignIn) return;

      if (M.FOH || M.TWC) {
        const data = yield call(UsersApi.oneTimeToken);
        const sgPurchaseUrl = `${process.env.REACT_APP_MEMBERSHIP_PURCHASE_URL}${pathWithQuery}&ott=${data.oneTimeToken}&origin=${window.location.origin}`;
        window.open(sgPurchaseUrl, '_self');
        return;
      }

      yield put(push(pathWithQuery));
      return;
    }

    const redirectUrl = `${pathname}${search}`;
    const encodedUrl = encodeURIComponent(redirectUrl);

    yield put(NetworkMembershipActions.setNetworkMembershipRedirectTo(redirectUrl));

    yield put(push(`${NETWORK_MEMBERSHIP_SALES}?redirectTo=${encodedUrl}${meta?.isWebView ? '&webView=true' : ''}`));
    yield put(NetworkMembershipActions.redirectToNetworkMembershipDone());
  } catch {
    yield put(NetworkMembershipActions.redirectToNetworkMembershipError());
  }
}

export function* requestNetworkMembershipBenefits({ courseId }) {
  try {
    const benefits = yield call(CourseDetailApi.getMembershipBenefits, courseId);
    yield put(NetworkMembershipActions.getNetworkMembershipBenefitsDone(benefits));
  } catch (error) {
    yield put(NetworkMembershipActions.getNetworkMembershipBenefitsError({ error }));
  }
}

export function* requestNetworkMembershipCourses() {
  try {
    const { states } = yield call(NetworkMembership.getNetworkMembershipCourses);

    const networkCourses = states?.map((state) => {
      const sortedCourses = sortCourses(state?.courses);
      return {
        name: state.name,
        courses: sortedCourses,
      };
    });

    yield put(NetworkMembershipActions.getNetworkMembershipCoursesDone(networkCourses));
  } catch (error) {
    yield put(NetworkMembershipActions.getNetworkMembershipCoursesError({ error }));
  }
}

export function* requestNetworkMembershipPlans() {
  try {
    const { plans, benefits } = yield call(NetworkMembership.getNetworkMembershipPlans);

    yield put(NetworkMembershipActions.getNetworkMembershipPlansDone(plans, benefits));
  } catch (error) {
    yield put(NetworkMembershipActions.getNetworkMembershipPlansError({ error }));
  }
}

export function* requestGolfClubCourses({ withLocation, withBanner }) {
  try {
    const { lat, lon } = yield select((state) => state.location);
    const query = {};
    if (lat && lon && withLocation) {
      query.lat = lat;
      query.lon = lon;
    }
    query.limit = 3;
    if (withBanner) query.includeBanner = true;
    const { courses, banner } = yield call(NetworkMembership.getGolfClubCourses, query);
    yield put(NetworkMembershipActions.getGolfClubCoursesDone(courses, banner ?? null));
  } catch (error) {
    const errorMessage = error?.message || error?.response?.data?.error;
    yield put(NetworkMembershipActions.getGolfClubCoursesError(errorMessage));
  }
}

export function* requestNetworkMembershipSubscription() {
  try {
    const { membershipSubscription } = yield call(
      NetworkMembership.getNetworkMembershipSubscription,
    );

    yield put(NetworkMembershipActions.getNetworkMembershipSubscriptionDone(
      membershipSubscription,
    ));
    yield put(
      NetworkMembershipActions.setNetworkMembershipCreditCard(membershipSubscription.creditCard),
    );
  } catch (error) {
    yield put(NetworkMembershipActions.getNetworkMembershipSubscriptionDone({ error }));
  }
}

export function* requestGolfVantageUrl({ callback }) {
  try {
    const { loginUrl } = yield call(NetworkMembership.getGolfVantageUrl);

    yield put(NetworkMembershipActions.getGolfVantageUrlDone(loginUrl));

    if (callback) callback({ url: loginUrl });
  } catch (error) {
    yield put(NetworkMembershipActions.getGolfVantageUrlError({ error }));
  }
}

export function* purchaseNetworkMembership({ creditCardId, billingPlanId, callback }) {
  try {
    const iterable = yield getContext('iterable');
    const gtm = yield getContext('gtm');
    const iterableTracking = yield call(iterable.getTrackingParams, true);
    const LS = yield getContext('localStorage');
    const utmParams = yield call(LS.getUtmParams);

    const response = yield call(
      NetworkMembership.purchaseNetworkMembership,
      {
        creditCardId, billingPlanId, iterableTracking, utmParams,
      },
    );

    const userProfile = yield call(UsersApi.userProfile);
    yield put(ProfileActions.setProfile(userProfile.user));

    const success = response?.status === 201 && 'success';
    yield call(gtm.trackEvent, {
      eventCategory: 'national-membership',
      eventAction: 'success',
      eventLabel: 'purchase-national-membership',
      event: 'purchase-national-membership-success',
    });
    yield put(NetworkMembershipActions.purchaseNetworkMembershipDone(success));
    if (callback) callback({ isSuccess: true });
  } catch (error) {
    let errorMessage = error?.message || error?.response?.data?.error;
    let status = '';
    if (error?.response?.status === 400) {
      status = 'failure';
    }
    if (errorMessage === 'Payment Error') {
      errorMessage = 'Payment failed, please try again.';
    }
    const gtm = yield getContext('gtm');
    yield call(gtm.trackEvent, {
      eventCategory: 'national-membership',
      eventAction: 'failure',
      eventLabel: 'purchase-national-membership',
      event: 'purchase-national-membership-failure',
    });

    if (callback) callback({ isError: true });
    yield put(NetworkMembershipActions.purchaseNetworkMembershipError(status, errorMessage));
  }
}

export function* trackNetworkMembershipPurchase({ planId }) {
  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams, true);

  yield call(NetworkMembership.trackNetworkMembershipPurchase, {
    planId, iterableTracking,
  });
}

export function* changeCreditCard({ creditCardId }) {
  try {
    yield call(NetworkMembership.changeCreditCard, creditCardId);
    yield put(NetworkMembershipActions.changeCreditCardDone());
    yield put(NetworkMembershipActions.getNetworkMembershipSubscriptionSilent());
  } catch (error) {
    let errorMessage = error?.message;
    if (error.response.status === 400) {
      errorMessage = 'Credit card could not be changed';
    }
    yield put(NetworkMembershipActions.changeCreditCardError(errorMessage));
  }
}

export function* cancelNetworkMembership() {
  try {
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams, true);

    const response = yield call(NetworkMembership.cancelNetworkMembership, { iterableTracking });
    const success = response?.status === 200 && 'cancelled';
    yield put(NetworkMembershipActions.cancelNetworkMembershipDone(success));
    yield put(NetworkMembershipActions.getNetworkMembershipSubscription());
  } catch (error) {
    let errorMessage = error?.message;
    if (error.response.status === 400) {
      errorMessage = 'failure';
    }
    yield put(NetworkMembershipActions.cancelNetworkMembershipError(errorMessage));
  }
}

export function* renewNetworkMembership() {
  try {
    const iterable = yield getContext('iterable');
    const iterableTracking = yield call(iterable.getTrackingParams, true);
    const response = yield call(NetworkMembership.renewNetworkMembership, { iterableTracking });
    const success = response?.status === 200 && 'renewed';
    yield put(NetworkMembershipActions.renewNetworkMembershipDone(success));
    yield put(NetworkMembershipActions.getNetworkMembershipSubscription());
  } catch (error) {
    let errorMessage = error?.message;
    if (error.response.status === 400) {
      errorMessage = 'failure';
    }
    yield put(NetworkMembershipActions.renewNetworkMembershipError(errorMessage));
  }
}

export function* requestCoursesWithOffers({ status }) {
  try {
    const response = yield call(NetworkMembership.getCoursesWithOffers, { status });
    yield put(NetworkMembershipActions.getCoursesWithOffersDone(response?.offers || []));
  } catch (error) {
    yield put(NetworkMembershipActions.getCoursesWithOffersError(error));
  }
}

export function* requestCourseOffers({ id }) {
  try {
    const response = yield call(NetworkMembership.getCourseOffers, { id });
    yield put(NetworkMembershipActions.getCourseOffersDone(response?.offers || []));
  } catch (error) {
    yield put(NetworkMembershipActions.getCourseOffersError(error));
  }
}

export function* requestRedeemNetworkOffer({ id, meta }) {
  try {
    yield call(NetworkMembership.redeemCourseOffer, { id });
    const response = yield call(NetworkMembership.getCourseOffers, { id: meta?.id });
    yield put(NetworkMembershipActions.redeemNetworkOfferDone(response?.offers || []));
    if (meta?.afterDone) meta.afterDone();
  } catch (error) {
    yield put(NetworkMembershipActions.redeemNetworkOfferError(error));
  }
}

function* redirectToNetworkMembershipWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.REDIRECT_TO_NETWORK_MEMBERSHIP,
    redirectToNetworkMembershipHandler,
  );
}

function* requestNetworkMembershipBenefitsWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_NETWORK_MEMBERSHIP_BENEFITS,
    requestNetworkMembershipBenefits,
  );
}

function* requestNetworkMembershipCoursesWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_NETWORK_MEMBERSHIP_COURSES,
    requestNetworkMembershipCourses,
  );
}

function* requestNetworkMembershipPlansWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_NETWORK_MEMBERSHIP_PLANS,
    requestNetworkMembershipPlans,
  );
}

function* requestGolfClubCoursesWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_GOLF_CLUB_COURSES,
    requestGolfClubCourses,
  );
}

function* requestNetworkMembershipSubscriptionWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION,
    requestNetworkMembershipSubscription,
  );
}

function* requestNetworkMembershipSubscriptionSilentWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_NETWORK_MEMBERSHIP_SUBSCRIPTION_SILENT,
    requestNetworkMembershipSubscription,
  );
}

function* requestGolfVantageUrlWatcher() {
  yield takeLatest(NetworkMembershipTypes.GET_GOLF_VANTAGE_URL, requestGolfVantageUrl);
}

function* purchaseNetworkMembershipWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.PURCHASE_NETWORK_MEMBERSHIP,
    purchaseNetworkMembership,
  );
}

function* trackNetworkMembershipPurchaseWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.TRACK_NETWORK_MEMBERSHIP_PURCHASE,
    trackNetworkMembershipPurchase,
  );
}

function* changeCreditWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.CHANGE_CREDIT_CARD,
    changeCreditCard,
  );
}

function* cancelNetworkMembershipWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.CANCEL_NETWORK_MEMBERSHIP,
    cancelNetworkMembership,
  );
}

function* renewNetworkMembershipWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.RENEW_NETWORK_MEMBERSHIP,
    renewNetworkMembership,
  );
}

function* requestCoursesWithOffersWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_COURSES_WITH_OFFERS,
    requestCoursesWithOffers,
  );
}

function* requestCourseOffersWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.GET_COURSE_OFFERS,
    requestCourseOffers,
  );
}

function* requestRedeemNetworkOfferWatcher() {
  yield takeLatest(
    NetworkMembershipTypes.REDEEM_NETWORK_OFFER,
    requestRedeemNetworkOffer,
  );
}

export default [
  redirectToNetworkMembershipWatcher,
  requestNetworkMembershipBenefitsWatcher,
  requestNetworkMembershipCoursesWatcher,
  requestNetworkMembershipPlansWatcher,
  requestGolfClubCoursesWatcher,
  requestNetworkMembershipSubscriptionSilentWatcher,
  requestNetworkMembershipSubscriptionWatcher,
  requestGolfVantageUrlWatcher,
  purchaseNetworkMembershipWatcher,
  trackNetworkMembershipPurchaseWatcher,
  changeCreditWatcher,
  cancelNetworkMembershipWatcher,
  renewNetworkMembershipWatcher,
  requestCoursesWithOffersWatcher,
  requestCourseOffersWatcher,
  requestRedeemNetworkOfferWatcher,
];
