import moment from 'moment';
import capitalize from 'lodash/capitalize';

class DateHelper {
  static DEFAULT_FORMAT = 'YYYY-MM-DD';

  static COMPLETE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  static WEATHER_FORMAT = 'ddd, MMM D';

  static DAY_NAVIGATION_BIG_FORMAT = 'dddd, MMMM D';

  static DAY_NAVIGATION_SMALL_FORMAT = 'MMM D';

  static TIME_FORMAT = 'h A';

  static STANDARD_USA_DATE = 'MM/DD/YY';

  static DATE_LARGE_MONTH = 'MMMM';

  static TIME_SLOT = 'LT';

  static MMMM_DD_YYYY = 'MMMM DD, YYYY';

  static MMM_DO_YYYY = 'MMM Do, YYYY';

  static REFUNDABLE = 'h:mm a on MMM DD, YYYY'

  static RATE_TYPE = 'MMMM DD, ';

  static MMMM_D = 'MMMM D';

  static MONTH_YEAR = 'MMM, YYYY';

  static TEE_TIME_FORMAT = 'h:mmA';

  static STANDARD_TIME = 'h:mm a'

  static formatDate(date, format = DateHelper.DEFAULT_FORMAT, utc = false) {
    if (utc) {
      return moment(date).utc().format(format);
    }
    return moment(date).format(format);
  }

  static formatDateWithoutTime(date) {
    return moment(date).startOf('day').format(DateHelper.DEFAULT_FORMAT);
  }

  static formatDateAtLocal(date, format = DateHelper.DEFAULT_FORMAT) {
    return moment(date).parseZone().format(format);
  }

  static getDateFromPathWithDefault(date, format = DateHelper.DEFAULT_FORMAT) {
    if (!date) {
      return moment();
    }

    const parsedDate = moment(capitalize(date), format);
    if (parsedDate.isBefore(moment(), 'day')) {
      return moment();
    }
    return parsedDate;
  }

  static formatTime(date, standard = false, parseZone = false) {
    let time = moment(date).utc();
    if (parseZone) time = time.parseZone();
    if (standard) {
      return time.format(DateHelper.STANDARD_TIME).split('M')[0];
    }
    return time.format(DateHelper.TEE_TIME_FORMAT).split('M')[0];
  }
}

export default DateHelper;
