import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import ProfileActions from 'reducers/profile';
import SessionActions from 'reducers/session';
import RegistrationActions from 'reducers/registrations';
import WelcomeActions, { WelcomeTypes } from 'reducers/welcome';

import UsersApi from 'apis/supremeGolfApi/UsersApi';

import { SIGN_IN, HOME } from 'utils/routes';

export function* welcome() {
  try {
    const { user } = yield call(UsersApi.userProfile);

    yield put(ProfileActions.setProfile(user));

    const agreementsAccepted = [
      user.consumerPrivacyAgreements.promotionalOptOut,
      user.consumerPrivacyAgreements.weeklyOptOut,
      user.consumerPrivacyAgreements.contestsAndSweepstakesOptOut,
      user.consumerPrivacyAgreements.newsletterOptOut,
    ].find((agreement) => (agreement && agreement.date));

    if (agreementsAccepted) {
      const { path } = yield select((state) => state.session.redirectTo);
      if (path) {
        yield put(push(path));
      } else {
        yield put(SessionActions.redirectTo({ path: HOME }));
        yield put(push(HOME));
      }
    } else {
      yield put(WelcomeActions.welcomeLoaded());
    }
  } catch (error) {
    yield put(SessionActions.redirectTo({ path: HOME }));
    yield put(push(SIGN_IN));
  }
}

export function* welcomeOptInHandler({ preferences }) {
  try {
    const response = yield call(UsersApi.editUser, preferences);
    const { path, action } = yield select((state) => state.session.redirectTo);

    yield put(RegistrationActions.editUserDone(response));
    yield put(WelcomeActions.welcomeOptInDone());
    yield put(ProfileActions.setProfile(response.user));

    if (path) {
      yield put(push(path));
    } else {
      yield put(SessionActions.redirectTo({ path: HOME }));
      yield put(push(HOME));
    }

    if (action) {
      yield action();
    }
  } catch (error) {
    const errorMessage = error.response.data.error;

    yield put(RegistrationActions.editUserError(errorMessage));
    yield put(WelcomeActions.welcomeOptInError());
  }
}

function* welcomeWatcher() {
  yield takeLatest(WelcomeTypes.WELCOME, welcome);
}

function* welcomeOptInWatcher() {
  yield takeLatest(WelcomeTypes.WELCOME_OPT_IN, welcomeOptInHandler);
}

export default [
  welcomeWatcher,
  welcomeOptInWatcher,
];
