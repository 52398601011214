import {
  call, put, take, takeLatest, getContext, select, delay,
} from 'redux-saga/effects';
import { push, goBack, replace } from 'react-router-redux';
import Route from 'route-parser';

import {
  CHECKOUT,
  CONFIRM_DELETION,
  HOME,
  MY_ACCOUNT_PREFIX, SIGN_IN,
  NOT_FOUND,
  UNLOCK_ACCOUNT_FAILED,
  VERIFY_CUSTOMER_DETAILS,
  WELCOME_TO_SUPREME,
  BOOKINGS_INVITE,
} from 'utils/routes';

import CheckoutActions from 'reducers/checkout';
import SessionActions, { SessionTypes } from 'reducers/session';
import PaymentMethodsActions from 'reducers/paymentMethods';
import ProfileActions from 'reducers/profile';
import SearchActions from 'reducers/searchParams';
import LoginApi from 'apis/supremeGolfApi/LoginApi';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import { isProfileComplete } from 'utils/profileHelpers';
import { matchRoute } from 'utils/routeHelpers';

export function* goToSignInHandler(redirectTo) {
  const profile = yield select((state) => state.profile);
  if (profile.loggedIn) return false;
  if (redirectTo) {
    if (redirectTo.isLinkOff) return false;

    yield put(SessionActions.redirectTo(redirectTo));
  }
  yield delay(1);
  yield put(push(SIGN_IN));
  return true;
}

export function* goToVerifyCustomerDetailsHandler(user, { pathname, search }) {
  if (!user
    || !pathname
    || matchRoute(NOT_FOUND, pathname)
    || matchRoute(CHECKOUT, pathname)
    || matchRoute(VERIFY_CUSTOMER_DETAILS, pathname)
    || matchRoute(WELCOME_TO_SUPREME, pathname)
  ) {
    if (matchRoute(VERIFY_CUSTOMER_DETAILS, pathname)) yield put(push(NOT_FOUND));
    return false;
  }

  if (user.forceDataCheck || (!isProfileComplete(user) && user.requiresDataCheck)) {
    yield put(ProfileActions.editProfile({ isDataChecked: true }));
    yield put(SessionActions.redirectTo({
      path: `${pathname}${search}`,
      newWindow: false,
      trackEvent: null,
    }));
    yield put(push(VERIFY_CUSTOMER_DETAILS));
    return true;
  }
  return false;
}

function* checkUnlockStatusHandler(unlockSuccess) {
  if (unlockSuccess === 'false') {
    yield put(push(UNLOCK_ACCOUNT_FAILED));
  } else if (unlockSuccess === 'true') {
    yield put(SessionActions.unlockError());
  }
}

export function* requestLoginHandler({
  email,
  password,
  rememberMe,
  callback,
}) {
  let invitationToken = null;
  try {
    const gtm = yield getContext('gtm');
    const {
      path,
      newWindow,
      trackEvent,
      action,
    } = yield select((state) => state.session.redirectTo);

    yield call(gtm.trackEvent, {
      eventCategory: 'sign in', eventAction: 'click', eventLabel: 'sign-in-top-nav', event: 'click-sign-in',
    });

    const bookingsInviteRoute = new Route(BOOKINGS_INVITE);
    const { inviteId } = bookingsInviteRoute.match(path);
    if (inviteId) invitationToken = inviteId;

    const login = yield call(
      LoginApi.login,
      email,
      password,
      rememberMe,
      invitationToken,
    );

    yield call(gtm.trackEvent, {
      eventCategory: 'sign in', eventAction: 'submit', eventLabel: 'success', event: 'sign-in-success',
    });

    yield put(SessionActions.loginDone(login));
    const LS = yield getContext('localStorage');

    yield call(LS.setToken, login.token);

    const userProfile = yield call(
      UsersApi.userProfile,
    );

    yield put(ProfileActions.setProfile(userProfile.user));
    const goToVerifyCustomerDetails = yield goToVerifyCustomerDetailsHandler(
      userProfile.user, { pathname: path || HOME, search: '' },
    );
    if (goToVerifyCustomerDetails) return;

    if (action) {
      yield action();
    }

    if (callback) {
      callback();
    }

    if (!path || path === '/sign-in') {
      yield put(replace(HOME));
      return;
    }

    if (trackEvent) {
      yield call(gtm.trackEvent, trackEvent);
    }

    if (newWindow) {
      window.open(path, '_blank');
      yield put(goBack());
    } else if (inviteId) {
      yield put(replace(path));
    } else {
      yield put(replace(path));
    }
  } catch (error) {
    if (callback) { callback(); }

    if (error && error.response && error.response.status === 400) {
      yield put(SessionActions.loginError(error.response.data.error));
    } else {
      yield put(SessionActions.loginError(error.message));
    }
  }
}

export function* restoreSessionHandler() {
  try {
    const LS = yield getContext('localStorage');
    const hasToken = yield call(LS.hasToken);
    const location = yield select((state) => state.router.location);
    const { pathname, search, hash } = location;
    const queryParams = new URLSearchParams(search);
    const oneTimeToken = queryParams.get('oneTimeToken');
    const ott = queryParams.get('ott');
    const unlockSuccess = queryParams.get('unlockSuccess');
    queryParams.delete('oneTimeToken');
    queryParams.delete('ott');
    const currentUrlWithoutOtt = `${pathname}?${queryParams.toString()}`;

    if (oneTimeToken || ott) {
      try {
        const ottLoginResponse = yield call(UsersApi.signInWithOneTimeToken, ott);

        yield call(LS.setToken, ottLoginResponse.token);
      } catch (error) {
        const {
          response: {
            data: {
              error: errorMessage,
            } = {},
          } = {},
        } = error;

        if (errorMessage === 'Token is invalid') {
          yield put(push(currentUrlWithoutOtt));
        }
      }

      const userProfile = yield call(UsersApi.userProfile);

      yield put(ProfileActions.setProfile(userProfile.user));
      const goToVerifyCustomerDetails = yield goToVerifyCustomerDetailsHandler(
        userProfile.user, location,
      );
      if (goToVerifyCustomerDetails) return;
      yield put(push(currentUrlWithoutOtt));
    } else if (hasToken) {
      const userProfile = yield call(UsersApi.userProfile);

      yield put(ProfileActions.setProfile(userProfile.user));
      yield goToVerifyCustomerDetailsHandler(userProfile.user, location);
    } else if (pathname.includes(MY_ACCOUNT_PREFIX) && !matchRoute(CONFIRM_DELETION, pathname)) {
      const queryParamsString = queryParams.toString() ? `?${queryParams.toString()}` : '';
      const currentUrl = `${pathname}${hash}${queryParamsString}`;
      const goToSignIn = yield goToSignInHandler({
        path: currentUrl,
        newWindow: false,
        trackEvent: null,
      });
      if (goToSignIn) {
        yield put(SessionActions.restoreSessionDone());
        return;
      }

      const { isModerator } = yield select((state) => state.profile);
      if (!isModerator && pathname.includes('/my-account/manage-reviews')) {
        yield put(replace('/not-found'));
      }
    } else if (unlockSuccess) {
      yield checkUnlockStatusHandler(unlockSuccess);
    }

    yield put(SessionActions.restoreSessionDone());
  } catch (error) {
    yield put(SessionActions.restoreSessionError(error.message));
  }
}

export function* requestSignOutHandler({ withRedirection }) {
  try {
    yield call(UsersApi.signOut);

    yield put(SessionActions.signOutDone());

    const LS = yield getContext('localStorage');
    yield call(LS.removeKey, 'X-Api-User-Token');
    yield call(LS.removeKey, 'has-sg-club-2020');

    if (withRedirection) {
      yield put(push('/'));
    }

    yield put(SearchActions.hardResetFilters());
    yield put(ProfileActions.resetProfile());
    yield put(PaymentMethodsActions.resetCreditCards());
    yield put(CheckoutActions.resetCheckout());
  } catch (error) {
    yield put(SessionActions.signOutError(error.message));
  }
}

function* redirectIfSessionHandler({ redirectTo }) {
  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((action) => (
      action.type === SessionTypes.RESTORE_SESSION_DONE
      || action.type === SessionTypes.RESTORE_SESSION_ERROR
    ));
  }
  const { loggedIn } = yield select((state) => state.profile);
  if (loggedIn) {
    yield put(push(redirectTo));
  }
}

function* trackSessionHandler() {
  const LS = yield getContext('localStorage');
  const token = yield LS.getSessionToken();
  if (token) {
    yield put(SessionActions.getSessionTokenDone());
    return;
  }
  try {
    const data = yield call(UsersApi.trackSession);
    const { apiSessionToken } = data;
    yield call(LS.setSessionToken, apiSessionToken);
    yield put(SessionActions.getSessionTokenDone());
  } catch (error) {
    yield put(SessionActions.getSessionTokenError(error));
  }
}

function* redirectIfSessionWatcher() {
  yield takeLatest(SessionTypes.REDIRECT_IF_SESSION,
    redirectIfSessionHandler);
}

function* restoreSessionWatcher() {
  yield takeLatest(SessionTypes.RESTORE_SESSION,
    restoreSessionHandler);
}

function* requestLoginWatcher() {
  yield takeLatest(SessionTypes.LOGIN,
    requestLoginHandler);
}

function* requestSignOutWatcher() {
  yield takeLatest(SessionTypes.SIGN_OUT,
    requestSignOutHandler);
}

function* trackSessionWatcher() {
  yield takeLatest(SessionTypes.GET_SESSION_TOKEN, trackSessionHandler);
}

export default [
  requestLoginWatcher,
  restoreSessionWatcher,
  requestSignOutWatcher,
  redirectIfSessionWatcher,
  trackSessionWatcher,
];
