import {
  call, put, takeLatest, getContext, select,
} from 'redux-saga/effects';
import humps from 'humps';

import CourseSuggestionsActions, {
  CourseSuggestionsTypes,
} from 'reducers/courseSuggestions';
import TrackingActions from 'reducers/tracking';
import CourseSuggestionsApi from 'apis/supremeGolfApi/CourseSuggestionsApi';
import LocationHelper from 'utils/locationHelper';

export function* requestCoursesNearbyHandler() {
  try {
    const location = yield call(LocationHelper.getUserLocation);
    const coursesNearYou = yield call(CourseSuggestionsApi.getCoursesNearYou, location);
    yield put(CourseSuggestionsActions.getCoursesNearYouDone(coursesNearYou));
  } catch (error) {
    yield put(CourseSuggestionsActions.getCoursesNearYouError(error.message));
  }
}

export function* requestCoursesNearbySilentHandler() {
  try {
    const location = yield call(LocationHelper.getUserLocation);
    const coursesNearYou = yield call(CourseSuggestionsApi.getCoursesNearYou, location);
    yield put(CourseSuggestionsActions.getCoursesNearYouDone(coursesNearYou));
  } catch (error) {
    yield put(CourseSuggestionsActions.getCoursesNearYouError(error.message));
  }
}

export function* createCourseSuggestionHandler({ courseSuggestion }) {
  let errorMessage = '';
  try {
    yield call(CourseSuggestionsApi.createCourseSuggestion, courseSuggestion);

    yield put(CourseSuggestionsActions.createCourseSuggestionDone());
  } catch (error) {
    const {
      response: {
        data: {
          error: niceError,
        } = {},
      } = {},
    } = error;
    errorMessage = niceError || error.message;
    yield put(CourseSuggestionsActions.createCourseSuggestionError(errorMessage));
  }
}

export function* sendCourseSuggestionHandler({ courseId, courseSuggestion, callback }) {
  let errorMessage = '';
  try {
    yield call(CourseSuggestionsApi.sendCourseSuggestion, courseId, courseSuggestion);
    yield put(CourseSuggestionsActions.sendCourseSuggestionDone());
  } catch (error) {
    const { response: { data: { error: niceError } = {} } = {} } = error;
    errorMessage = niceError || error.message;
    yield put(CourseSuggestionsActions.sendCourseSuggestionError(errorMessage));
  }
  if (callback) {
    callback(errorMessage);
  }
}

export function* trackCourseSuggestionEventHandler({ eventParams }) {
  if (!eventParams.event) return;

  const profile = yield select((state) => state.profile);
  const { course } = yield select(({ courseDetail }) => courseDetail);
  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams);

  const gtm = yield getContext('gtm');

  yield call(gtm.trackEvent, {
    eventCategory: 'no-tee-times',
    eventAction: eventParams.eventAction,
    eventLabel: humps.decamelize(eventParams.event, { separator: '-' }),
    event: eventParams.event,
  });

  yield put(TrackingActions.trackEvent(eventParams.event, {
    email: profile?.email,
    campaignId: iterableTracking?.campaign_id,
    templateId: iterableTracking?.template_id,
    messageId: iterableTracking?.message_id,
    userId: profile?.id,
    platform: 'web',
    course: {
      slug: course?.slug,
      name: course?.name,
      zipcode: course?.addressZipcode,
    },
    ...eventParams?.iterableParams,
  }));
}

function* createCourseSuggestionWatcher() {
  yield takeLatest(CourseSuggestionsTypes.CREATE_COURSE_SUGGESTION, createCourseSuggestionHandler);
}

function* requestCoursesNearbyWatcher() {
  yield takeLatest(CourseSuggestionsTypes.GET_COURSES_NEAR_YOU, requestCoursesNearbyHandler);
}

function* requestCoursesNearbySilentWatcher() {
  yield takeLatest(
    CourseSuggestionsTypes.GET_COURSES_NEAR_YOU_SILENT, requestCoursesNearbySilentHandler,
  );
}

function* sendCourseSuggestionWatcher() {
  yield takeLatest(CourseSuggestionsTypes.SEND_COURSE_SUGGESTION, sendCourseSuggestionHandler);
}

function* trackCourseSuggestionEventWatcher() {
  yield takeLatest(
    CourseSuggestionsTypes.TRACK_COURSE_SUGGESTION_EVENT, trackCourseSuggestionEventHandler,
  );
}

export default [
  createCourseSuggestionWatcher,
  requestCoursesNearbyWatcher,
  requestCoursesNearbySilentWatcher,
  sendCourseSuggestionWatcher,
  trackCourseSuggestionEventWatcher,
];
