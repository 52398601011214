const moderatorReviewStatuses = {
  'auto-published': {
    label: 'Auto Published',
    borderStyles: 'border-0 rounded-l-larger',
    scope: 'autopublished',
  },
  'needs-review': {
    label: 'Needs Review',
    borderStyles: 'border-l',
    scope: 'for_review',
  },
  approved: {
    label: 'Approved & Published',
    borderStyles: 'border-l',
    scope: 'approved',
  },
  rejected: {
    label: 'Rejected',
    borderStyles: 'border-l rounded-r-larger',
    scope: 'rejected',
  },
};

export default moderatorReviewStatuses;
