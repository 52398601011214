import {
  call, put, takeLatest, getContext, select,
} from 'redux-saga/effects';

import OffersActions, { OffersTypes } from 'reducers/offers';
import TrackingActions from 'reducers/tracking';
import OffersApi from 'apis/supremeGolfApi/OffersApi';

export function* requestOffersHandler() {
  try {
    const offers = yield call(OffersApi.getOffers);
    yield put(OffersActions.getOffersDone(offers));
  } catch (error) {
    yield put(OffersActions.trackOfferEvent({
      name: 'offersPageError',
      action: 'fetch',
      label: 'error',
    }));
    yield put(OffersActions.getOffersError(error.message));
  }
}

export function* requestRedeemOfferHandler({ id, afterRedeem }) {
  try {
    const offers = yield call(OffersApi.redeemOffer, id);
    yield put(OffersActions.redeemOfferDone(offers));
    if (afterRedeem) afterRedeem(offers);
  } catch (error) {
    if (afterRedeem) afterRedeem(null, error.message || error?.response?.data?.error);
    yield put(OffersActions.redeemOfferError(error.message || error?.response?.data?.error));
  }
}

export function* trackOfferEventHandler({ eventParams }) {
  if (!eventParams.name) return;

  const gtm = yield getContext('gtm');

  yield call(gtm.trackEvent, {
    eventCategory: 'offers',
    eventAction: eventParams.action,
    eventLabel: eventParams.label,
    event: eventParams.name,
    ...eventParams.gtmParams,
  });

  const profile = yield select((state) => state.profile);
  const iterable = yield getContext('iterable');
  const iterableTracking = yield call(iterable.getTrackingParams);

  yield put(TrackingActions.trackEvent(eventParams.name, {
    campaignId: iterableTracking?.campaign_id,
    templateId: iterableTracking?.template_id,
    messageId: iterableTracking?.message_id,
    email: profile?.email,
    userId: profile?.id,
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    platform: 'web',
  }));
}

function* requestOffersWatcher() {
  yield takeLatest(OffersTypes.GET_OFFERS, requestOffersHandler);
}

function* requestRedeemOfferWatcher() {
  yield takeLatest(OffersTypes.REDEEM_OFFER, requestRedeemOfferHandler);
}

function* trackOfferEventHandlerWatcher() {
  yield takeLatest(
    OffersTypes.TRACK_OFFER_EVENT, trackOfferEventHandler,
  );
}

export default [
  requestOffersWatcher,
  requestRedeemOfferWatcher,
  trackOfferEventHandlerWatcher,
];
