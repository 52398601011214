import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import RequestResetPasswordActions, {
  RequestResetPasswordTypes,
} from 'reducers/requestResetPassword';
import RequestResetPasswordApi from 'apis/supremeGolfApi/RequestResetPasswordApi';
import { SIGN_IN, HOME } from 'utils/routes';
import SessionActions from 'reducers/session';

export function* requestRequestResetPasswordHandler({
  email,
}) {
  try {
    const requestResetPassword = yield call(
      RequestResetPasswordApi.requestResetPassword,
      email,
    );

    yield put(SessionActions.redirectTo({ path: HOME }));
    yield put(RequestResetPasswordActions.requestResetPasswordDone(requestResetPassword));
  } catch (error) {
    const responseError = error?.response?.data?.error;
    if (error.message || responseError) {
      yield put(RequestResetPasswordActions.requestResetPasswordError(
        error.message || responseError,
      ));
    } else if (error && error.response
      && (error.response.status === 404 || error.response.status === 400)) {
      yield put(RequestResetPasswordActions.requestResetPasswordError('email not found'));
    }
  }
}

export function* requestRequestResetPasswordFinishHandler() {
  yield put(push(SIGN_IN));
}

function* requestRequestResetPasswordWatcher() {
  yield takeLatest(RequestResetPasswordTypes.REQUEST_RESET_PASSWORD,
    requestRequestResetPasswordHandler);
}

function* requestRequestResetPasswordFinishWatcher() {
  yield takeLatest(RequestResetPasswordTypes.REQUEST_RESET_PASSWORD_FINISH,
    requestRequestResetPasswordFinishHandler);
}

export default [
  requestRequestResetPasswordWatcher,
  requestRequestResetPasswordFinishWatcher,
];
