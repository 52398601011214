import SupremeGolfApiClient from './SupremeGolfApiClient';

const NETWORK_MEMBERSHIP = 'v6/network_memberships';

class NetworkMembership {
  static async getNetworkMembershipPlans() {
    const response = await SupremeGolfApiClient.get(`${NETWORK_MEMBERSHIP}/plans`);

    return response.data;
  }

  static async getNetworkMembershipCourses() {
    const response = await SupremeGolfApiClient.get('v6/states/network_membership');

    return response.data;
  }

  static async getGolfClubCourses(query) {
    const response = await SupremeGolfApiClient.get(`${NETWORK_MEMBERSHIP}/courses`, {
      ...query,
    });

    return response.data;
  }

  static async getNetworkMembershipSubscription() {
    const response = await SupremeGolfApiClient.get(`${NETWORK_MEMBERSHIP}/subscription`);

    return response.data;
  }

  static async getGolfVantageUrl() {
    const response = await SupremeGolfApiClient.get(`${NETWORK_MEMBERSHIP}/golf_vantage_url`);

    return response.data;
  }

  static async trackNetworkMembershipPurchase({ planId, iterableTracking }) {
    await SupremeGolfApiClient.post(`${NETWORK_MEMBERSHIP}/initiate_purchase`, {
      billingPlanId: planId, ...iterableTracking,
    });
  }

  static async purchaseNetworkMembership({
    creditCardId,
    billingPlanId,
    iterableTracking,
    utmParams = {},
  }) {
    const response = await SupremeGolfApiClient.post(NETWORK_MEMBERSHIP, {
      creditCardId, billingPlanId, ...iterableTracking, ...utmParams,
    });

    return response;
  }

  static async changeCreditCard(creditCardId) {
    const response = await SupremeGolfApiClient.post(`${NETWORK_MEMBERSHIP}/change-card/${creditCardId}`, { creditCardId });

    return response;
  }

  static async cancelNetworkMembership({ iterableTracking }) {
    const response = await SupremeGolfApiClient.delete(NETWORK_MEMBERSHIP, {
      ...iterableTracking,
    });

    return response;
  }

  static async renewNetworkMembership({ iterableTracking }) {
    const response = await SupremeGolfApiClient.put(`${NETWORK_MEMBERSHIP}/withdraw_cancellation`, { ...iterableTracking });

    return response;
  }

  static async getNetworkMembershipInvoices() {
    const response = await SupremeGolfApiClient.get(`${NETWORK_MEMBERSHIP}/invoices`);

    return response.data;
  }

  static async getCoursesWithOffers({ status }) {
    const response = await SupremeGolfApiClient.get('v6/offers/membership', { status });

    return response.data;
  }

  static async getCourseOffers({ id }) {
    const response = await SupremeGolfApiClient.get(`v6/offers/membership/${id}`);
    return response.data;
  }

  static async redeemCourseOffer({ id }) {
    const response = await SupremeGolfApiClient.post(`v6/offers/${id}/redeem`);
    return response.data;
  }
}

export default NetworkMembership;

