import SupremeGolfApiClient from './SupremeGolfApiClient';

const FEEDBACK_ENDPOINT = 'v6/feedbacks';

class PagesApi {
  static async postFeedback(foundEverything, message) {
    const response = await SupremeGolfApiClient.post(`${FEEDBACK_ENDPOINT}`, {
      foundEverything,
      message,
    });

    return response.data;
  }

  static async postSupport(params) {
    const response = await SupremeGolfApiClient.post(
      `${FEEDBACK_ENDPOINT}/support-ticket`, params,
    );
    return response.data;
  }
}

export default PagesApi;
