import {
  put, call, takeLatest, take, select, getContext,
} from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import SessionActions, { SessionTypes } from 'reducers/session';
import ProfileActions, { ProfileTypes } from 'reducers/profile';
import CheckoutActions, { CheckoutTypes } from 'reducers/checkout';
import CourseReviewsActions from 'reducers/courseReviews';
import PasswordChangeActions, { PasswordChangeTypes } from 'reducers/passwordChange';
import TrackingActions from 'reducers/tracking';
import PaymentMethodsActions from 'reducers/paymentMethods';
import SearchActions from 'reducers/searchParams';
import AvatarApi from 'apis/supremeGolfApi/AvatarApi';
import UsersApi from 'apis/supremeGolfApi/UsersApi';
import { goToSignInHandler } from './session';

export function* retrieveProfile() {
  try {
    const userProfile = yield call(
      UsersApi.userProfile,
    );
    yield put(ProfileActions.setProfile(userProfile.user));
  } catch (error) {
    yield put(ProfileActions.getProfileError());
    yield put(SessionActions.restoreSessionError(error.message));
  }
}

export function* setProfile({ userProfile }) {
  const LS = yield getContext('localStorage');
  if (userProfile.membership) {
    yield call(LS.setValue, 'has-sg-club-2020', true);
  } else {
    yield call(LS.removeKey, 'has-sg-club-2020');
  }
}

export function* redirectIfNotSessionHandler() {
  const { pathname, search, hash } = yield select((state) => state.router.location);
  const queryParams = new URLSearchParams(search);
  const queryParamsString = queryParams.toString() ? `?${queryParams.toString()}` : '';
  const currentUrl = `${pathname}${hash}${queryParamsString}`;

  const { restoreSessionFinished } = yield select((state) => state.session);
  if (!restoreSessionFinished) {
    yield take((action) => (
      action.type === SessionTypes.RESTORE_SESSION_DONE
      || action.type === SessionTypes.RESTORE_SESSION_ERROR
    ));
  }

  const goToSignIn = yield goToSignInHandler({
    path: currentUrl,
    newWindow: false,
    trackEvent: null,
  });
  if (goToSignIn) return;

  const { isModerator } = yield select((state) => state.profile);

  if (!isModerator && pathname.includes('/my-account/manage-reviews')) {
    yield put(replace('/not-found'));
  }
}

export function* editProfileHandler({ profile, callback }) {
  let errorMessage = '';
  try {
    const updatedUserProfile = yield call(UsersApi.editUser, profile);

    yield put(ProfileActions.setProfile(updatedUserProfile.user));
    yield put(ProfileActions.editProfileDone());
  } catch (error) {
    const {
      response: {
        data: {
          error: niceError,
        } = {},
      } = {},
    } = error;
    errorMessage = niceError || error.message;
    yield put(ProfileActions.editProfileError(errorMessage));
  }

  if (callback) {
    callback(errorMessage);
  }
}

export function* setUserBanStatusHandler({ userID, isBanned }) {
  try {
    const data = yield call(UsersApi.setUserBanStatus, userID, isBanned);

    if (data) {
      let { reviewsList } = yield select((state) => state.courseReviews);
      reviewsList = reviewsList.map((review) => {
        if (review.reviewer.id === userID) {
          return { ...review, reviewer: { ...review.reviewer, isBanned } };
        }
        return review;
      });
      yield put(CourseReviewsActions.getCourseReviewsDone(reviewsList, false));
    }
  } catch (error) {
    yield put(CourseReviewsActions.getCourseReviewsError(error.message));
  }
}

export function* updateAvatarHandler({ formData, callback }) {
  try {
    const status = yield call(AvatarApi.updateAvatar, formData);
    if (status === 201) { yield put(ProfileActions.updateAvatarDone()); }
  } catch (error) {
    yield put(ProfileActions.updateAvatarError(error));
  }
  if (callback) {
    callback();
  }
}

export function* passwordChangeHandler({
  currentPassword,
  password,
  passwordConfirmation,
  callback,
}) {
  let errorMessage = '';
  try {
    yield call(UsersApi.passwordChange, currentPassword, password, passwordConfirmation);
    yield put(PasswordChangeActions.passwordChangeDone());
  } catch (error) {
    const {
      response: {
        data: {
          error: niceError,
        } = {},
      } = {},
    } = error;
    errorMessage = niceError || error.message;
    yield put(PasswordChangeActions.passwordChangeError(errorMessage));
  }

  if (callback) {
    callback(errorMessage);
  }
}

export function* performProfileCompletionTrackingHandler({ eventName }) {
  const gtm = yield getContext('gtm');
  const { isProfileComplete } = yield select(({ profile }) => profile);

  const event = eventName && isProfileComplete
    ? `profileCompletionSuccessful${eventName}`
    : `profileCompletionPopup${eventName}`;

  if (eventName === 'Checkout' && !isProfileComplete) {
    const { isDone } = yield select((state) => state.checkout);
    if (!isDone) {
      yield take((action) => (
        action.type === CheckoutTypes.GET_PREPARE_DONE
        || action.type === CheckoutTypes.GET_PREPARE_ERROR
      ));
    }
    const { preparedTeeTime } = yield select((state) => state.checkout);
    yield put(TrackingActions.trackEvent(
      event, { reservationId: preparedTeeTime.teeTimeReservationId },
    ));
  } else {
    yield put(TrackingActions.trackEvent(event, {}));
  }
  yield call(gtm.trackEvent, {
    eventCategory: 'ProfileCompletion',
    eventAction: isProfileComplete ? 'submit' : 'popup',
    eventLabel: isProfileComplete ? 'success' : 'incompleteProfile',
    event,
  });
}

export function* performProfileDeletionRequestHandler() {
  try {
    yield call(UsersApi.requestDeletion);
    yield put(ProfileActions.profileDeletionRequestDone());
  } catch (error) {
    const { response: { data: { error: { deleteAccount } } = {} } = {} } = error;
    const errorMessage = deleteAccount || error.message;
    yield put(ProfileActions.profileDeletionRequestError(errorMessage));
  }
}

export function* performDeleteProfileHandler({ token }) {
  try {
    yield call(UsersApi.deleteUser, token);
    const LS = yield getContext('localStorage');
    yield call(LS.removeKey, 'X-Api-User-Token');
    yield put(SearchActions.hardResetFilters());
    yield put(ProfileActions.resetProfile());
    yield put(PaymentMethodsActions.resetCreditCards());
    yield put(CheckoutActions.resetCheckout());
    yield put(ProfileActions.deleteProfileDone());
  } catch (error) {
    const { response: { data: { error: { deleteAccount } } = {} } = {} } = error;
    const errorMessage = deleteAccount || error.message;
    yield put(ProfileActions.deleteProfileError(errorMessage));
  }
}

function* retrieveProfileWatcher() {
  yield takeLatest(ProfileTypes.GET_PROFILE,
    retrieveProfile);
}

function* setProfileWatcher() {
  yield takeLatest(ProfileTypes.SET_PROFILE, setProfile);
}

function* redirectIfNotSessionWatcher() {
  yield takeLatest(ProfileTypes.REDIRECT_IF_NOT_SESSION,
    redirectIfNotSessionHandler);
}

function* editProfileWatcher() {
  yield takeLatest(ProfileTypes.EDIT_PROFILE, editProfileHandler);
}

function* updateAvatarWatcher() {
  yield takeLatest(ProfileTypes.UPDATE_AVATAR, updateAvatarHandler);
}

function* setUserBanStatusWatcher() {
  yield takeLatest(ProfileTypes.SET_USER_BAN_STATUS, setUserBanStatusHandler);
}

function* passwordChangeWatcher() {
  yield takeLatest(PasswordChangeTypes.PASSWORD_CHANGE, passwordChangeHandler);
}

function* performProfileCompletionTrackingWatcher() {
  yield takeLatest(ProfileTypes.PERFORM_PROFILE_COMPLETION_TRACKING,
    performProfileCompletionTrackingHandler);
}

function* profileDeletionRequestWatcher() {
  yield takeLatest(ProfileTypes.PROFILE_DELETION_REQUEST,
    performProfileDeletionRequestHandler);
}

function* deleteProfileWatcher() {
  yield takeLatest(ProfileTypes.DELETE_PROFILE,
    performDeleteProfileHandler);
}

export default [
  retrieveProfileWatcher,
  setProfileWatcher,
  redirectIfNotSessionWatcher,
  editProfileWatcher,
  updateAvatarWatcher,
  passwordChangeWatcher,
  setUserBanStatusWatcher,
  performProfileCompletionTrackingWatcher,
  profileDeletionRequestWatcher,
  deleteProfileWatcher,
];
