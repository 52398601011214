module.exports = {
  theme: {
    colors: {
      primary: '#cd2420',
      'primary-light': '#de5c54',
      secondary: '#222222',
      'secondary-light': '#7f7f7f',
      // Palette:
      first: '#000000',
      second: '#7f7f7f',
      third: '#7f7f7f',
      fourth: '#000000',
      fifth: '#222222',
      sixth: '#ffffff',
      seventh: '#7f7f7f',
      eighth: '#cd2420',
      ninth: '#7f7f7f',
      tenth: '#7f7f7f',
      eleventh: '#7f7f7f',
      twelfth: '#000000',
      thirteenth: '#7f7f7f',
      fourteenth: '#000000',
      fifteenth: '#7f7f7f',
      sixteenth: '#000000',
      seventeenth: '#222222',
      eighteenth: '#222222',
      nineteenth: '#7f7f7f',
      twentieth: '#041f3b',
      twentyFirst: '#7f7f7f',
      twentySecond: '#ffffff',
      twentyThird: '#cd2420',
      twentyFourth: '#cd2420',
      twentyFifth: '#feedea',
      twentySixth: '#7f7f7f',
      twentySeventh: '#cd2420',
      twentyEighth: '#bfbfbf',
      twentyNinth: '#43576c',
      thirtieth: '#000000',
      thirtyFirst: '#c0c7ce',
      thirtySecond: '#7f7f7f',
      thirtyThird: '#ffffff',
      thirtyFifth: '#cd2420',
      thirtySixth: '#ffffff',
      thirtySeventh: '#de5c54',
      thirtyEighth: '#d6dce2',
      thirtyNinth: '#7f7f7f',
      fortieth: '#222222',
      fortyFirst: '#56565a',
      fortySecond: '#222222',
      fortyThird: 'transparent',
      fortyFourth: '#adb9c5',
      fortyFifth: '#7f7f7f',
      fortySixth: '#cd2420',
      fortySeventh: '#7f7f7f',
      fortyEighth: '#f1f3f7',
      fortyNinth: 'transparent',
      fiftieth: '#feedea',
      fiftyFirst: '#7f7f7f',
      fiftySecond: '#484848',
      fiftyThird: '#484848',
      fiftyFourth: '#1d1d22',
      fiftyFifth: '#c6c6c8',
    },
    poweredBy: 'powered-by-white',
  },
};
