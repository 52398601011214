// Keep this file in alphabetical order.
module.exports = {
  breadcrumbs: false,
  claimCourse: false,
  courseOperators: false,
  courseOperatorsFreeMarketplace: false,
  courseOperatorsFreeMarketplaceSignupDetails: false,
  courseOperatorsVipPerks: false,
  courseOperatorsGolfCourseResources: false,
  courseReviews: false,
  featuredContests: false,
  courseSuggestion: false,
  dealsNav: false,
  dealsPage: false,
  disclaimerPage: true,
  exploreCountriesStates: false,
  extendedSupportForm: false,
  feedbackPage: true,
  forCourses: false,
  giftCardLink: false,
  golfTimePromotions: false,
  golfSpecials: false,
  hasNavMenu: false,
  hereToHelp: false,
  marketingOptInDefault: false,
  membership: false,
  moderator: false,
  myMemberships: false,
  nearMeNav: true,
  networkMembership: false,
  showNetworkSales: false,
  offers: false,
  partnerLogo: false,
  poweredByLogo: true,
  poweredByLogoScenery: false,
  priceAlerts: false,
  providerLogos: false,
  rewards: false,
  sectionCourses: false,
  shareReservation: false,
  showDealsOnly: false,
  showForeplayReviewedOnly: false,
  showNetworkMembershipFilter: false,
  storeUrl: false,
  subScenery: false,
  topDeals: true,
  barstoolVip: false,
};
