// Keep this file in alphabetical order.
module.exports = {
  breadcrumbs: false,
  claimCourse: false,
  courseOperators: false,
  courseOperatorsFreeMarketplace: false,
  courseOperatorsFreeMarketplaceSignupDetails: false,
  courseOperatorsVipPerks: false,
  courseOperatorsGolfCourseResources: false,
  courseReviews: true,
  featuredContests: true,
  courseSuggestion: false,
  dealsNav: false,
  dealsPage: false,
  deleteUser: false,
  disclaimerPage: false,
  exploreCountriesStates: false,
  extendedSupportForm: false,
  feedbackPage: false,
  forCourses: true,
  giftCardLink: false,
  golfTimePromotions: false,
  golfSpecials: false,
  hasNavMenu: true,
  hereToHelp: false,
  marketingCommunication: true,
  marketingOptInDefault: false,
  membership: false,
  moderator: false,
  myMemberships: false,
  nearMeNav: true,
  networkMembership: true,
  showNetworkSales: true,
  offers: false,
  partnerLogo: false,
  poweredByLogo: true,
  poweredByLogoScenery: false,
  priceAlerts: false,
  providerLogos: false,
  recommendedCourses: true,
  reviewAttachmentCta: false,
  rewardsAccumulation: false,
  rewardsReferral: false,
  sectionCourses: false,
  shareReservation: false,
  showDealsOnly: true,
  showForeplayReviewedOnly: false,
  showNearByAlertForm: false,
  showPrepaidRoundsFilter: true,
  showBarstoolBestFilter: false,
  showNetworkMembershipFilter: true,
  storeUrl: false,
  subScenery: false,
  supportReason: true,
  topDeals: true,
  barstoolVip: false,
};
