import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { PAYMENTS_HISTORY_PAGINATION } from 'utils/constants';

const { Types, Creators } = createActions({
  getMembershipDetails: [],
  getMembershipDetailsDone: ['membershipDetails'],
  getMembershipDetailsError: ['message'],
  deleteMembershipDetails: [],
  deleteMembershipDetailsDone: [],
  deleteMembershipDetailsError: ['message'],
  setMembershipCreditCard: ['creditCard'],
  setMembershipCreditCardDone: [],
  setMembershipCreditCardError: ['message'],
  getPaymentsHistory: [],
  getPaymentsHistoryDone: ['paymentsHistory', 'paymentsHistoryCount'],
  getPaymentsHistoryError: ['message'],
  getNextPaymentsHistoryPage: [],
  getNextPaymentsHistoryPageDone: ['paymentsHistory', 'paymentsHistoryCount'],
  getNextPaymentsHistoryPageError: ['message'],
});

export const MembershipDetailsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  membershipDetails: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
  isDeleteLoading: false,
  isDeleteError: false,
  deleteErrorMessage: '',
  isCreditCardLoading: false,
  isCreditCardError: false,
  creditCardErrorMessage: '',
  paymentsHistory: [],
  isPaymentsHistoryLoading: false,
  isPaymentsHistoryError: false,
  paymentsHistoryErrorMessage: '',
  paymentsHistoryCount: 0,
  paymentsHistoryPageNumber: 1,
  hasMorePaymentsHistoryPages: false,
  isLoadingMoreHistoryPages: false,
});

const getMembershipDetails = (state) => state.merge({
  isLoading: true,
  isError: false,
  errorMessage: '',
});

const getMembershipDetailsDone = (state, { membershipDetails }) => state.merge({
  isLoading: false,
  isError: false,
  membershipDetails,
});

const getMembershipDetailsError = (state, { message }) => state.merge({
  isLoading: false,
  isError: true,
  errorMessage: message,
});

const deleteMembershipDetails = (state) => state.merge({
  isDeleteLoading: true,
  isDeleteError: false,
  deleteErrorMessage: '',
});

const deleteMembershipDetailsDone = (state) => state.merge({
  isDeleteLoading: false,
  isDeleteError: false,
});

const deleteMembershipDetailsError = (state, { message }) => state.merge({
  isDeleteLoading: false,
  isDeleteError: true,
  deleteErrorMessage: message,
});

const setMembershipCreditCard = (state) => state.merge({
  isCreditCardLoading: true,
  isCreditCardError: false,
  creditCardErrorMessage: '',
});

const setMembershipCreditCardDone = (state) => state.merge({
  isCreditCardLoading: false,
  isCreditCardError: false,
  creditCardErrorMessage: '',
});

const setMembershipCreditCardError = (state, { message }) => state.merge({
  isCreditCardLoading: false,
  isCreditCardError: true,
  creditCardErrorMessage: message,
});

const getPaymentsHistory = (state) => state.merge({
  isPaymentsHistoryLoading: true,
  isPaymentsHistoryError: false,
  paymentsHistoryErrorMessage: '',
});

const getPaymentsHistoryDone = (state, { paymentsHistory, paymentsHistoryCount }) => state.merge({
  isPaymentsHistoryLoading: false,
  isPaymentsHistoryError: false,
  paymentsHistory,
  paymentsHistoryCount,
  hasMorePaymentsHistoryPages: (
    state.paymentsHistoryPageNumber * PAYMENTS_HISTORY_PAGINATION < paymentsHistoryCount
  ),
});

const getPaymentsHistoryError = (state, { message }) => state.merge({
  isPaymentsHistoryLoading: false,
  isPaymentsHistoryError: true,
  paymentsHistoryErrorMessage: message,
});

const getNextPaymentsHistoryPage = (state) => state.merge({
  isLoadingMoreHistoryPages: true,
  isError: false,
  paymentsHistoryErrorMessage: '',
});

const getNextPaymentsHistoryPageDone = (
  state, { paymentsHistory, paymentsHistoryCount },
) => state.merge({
  isLoadingMoreHistoryPages: false,
  isError: false,
  paymentsHistoryPageNumber: state.paymentsHistoryPageNumber + 1,
  hasMorePaymentsHistoryPages: (
    state.paymentsHistoryPageNumber * PAYMENTS_HISTORY_PAGINATION < paymentsHistoryCount
  ),
  paymentsHistory: [...state.paymentsHistory, ...paymentsHistory],
  paymentsHistoryErrorMessage: '',
});

const getNextPaymentsHistoryPageError = (state, { message }) => state.merge({
  isLoadingMoreHistoryPages: false,
  isError: true,
  paymentsHistoryErrorMessage: message,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MEMBERSHIP_DETAILS]: getMembershipDetails,
  [Types.GET_MEMBERSHIP_DETAILS_DONE]: getMembershipDetailsDone,
  [Types.GET_MEMBERSHIP_DETAILS_ERROR]: getMembershipDetailsError,
  [Types.DELETE_MEMBERSHIP_DETAILS]: deleteMembershipDetails,
  [Types.DELETE_MEMBERSHIP_DETAILS_DONE]: deleteMembershipDetailsDone,
  [Types.DELETE_MEMBERSHIP_DETAILS_ERROR]: deleteMembershipDetailsError,
  [Types.SET_MEMBERSHIP_CREDIT_CARD]: setMembershipCreditCard,
  [Types.SET_MEMBERSHIP_CREDIT_CARD_DONE]: setMembershipCreditCardDone,
  [Types.SET_MEMBERSHIP_CREDIT_CARD_ERROR]: setMembershipCreditCardError,
  [Types.GET_PAYMENTS_HISTORY]: getPaymentsHistory,
  [Types.GET_PAYMENTS_HISTORY_DONE]: getPaymentsHistoryDone,
  [Types.GET_PAYMENTS_HISTORY_ERROR]: getPaymentsHistoryError,
  [Types.GET_NEXT_PAYMENTS_HISTORY_PAGE]: getNextPaymentsHistoryPage,
  [Types.GET_NEXT_PAYMENTS_HISTORY_PAGE_DONE]: getNextPaymentsHistoryPageDone,
  [Types.GET_NEXT_PAYMENTS_HISTORY_PAGE_ERROR]: getNextPaymentsHistoryPageError,
});
