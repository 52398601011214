import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';

import constants, { NETWORK_MEMBERSHIP_BANNER_DEFAULT_RANGE } from 'utils/constants';

const { Types, Creators } = createActions({
  getTimeSlots: ['courseId', 'params'],
  getTimeSlotsDone: ['summary', 'timeSlotList'],
  getTimeSlotsError: ['message'],
  getNextPage: [],
  setSortByTimeSlots: ['sortBy'],
});

const sortByTime = (a, b) => (moment(a.teeOffAtLocal).isSameOrBefore(b.teeOffAtLocal) ? -1 : 1);

const sortTimeSlots = (timeSlots, sortBy) => [...timeSlots].sort((a, b) => {
  switch (sortBy) {
    case 'time':
      return sortByTime(a, b);
    case 'price-lowest-first':
      if (Number(a.startingRate) < Number(b.startingRate)) { return -1; }
      if (Number(a.startingRate) > Number(b.startingRate)) { return 1; }
      return sortByTime(a, b);
    case 'price-highest-first':
      if (Number(a.startingRate) > Number(b.startingRate)) { return -1; }
      if (Number(a.startingRate) < Number(b.startingRate)) { return 1; }
      return sortByTime(a, b);
    case 'deals':
      if (a.hotDeals && b.hotDeals) return sortByTime(a, b);
      if (a.hotDeals) return -1;
      if (b.hotDeals) return 1;
      return sortByTime(a, b);
    default:
      return 0;
  }
});

const applyFilters = ({ timeSlotList, sortBy }) => {
  const teeTimes = timeSlotList.filter((teeTime) => teeTime.type === 'tee_time');
  const membershipBanner = timeSlotList.filter((teeTime) => teeTime.type === 'banner');
  const teeTimesSorted = sortTimeSlots(teeTimes, sortBy);

  membershipBanner.forEach((banner, index) => {
    const indexToInsert = NETWORK_MEMBERSHIP_BANNER_DEFAULT_RANGE * index;
    teeTimesSorted.splice(indexToInsert, 0, banner);
  });

  return teeTimesSorted;
};

export const getPaginatedListSelector = (
  state,
) => applyFilters(state).slice(0, state.pageNumber * constants.TIME_SLOT_PAGINATION);

export const getListLengthSelector = (state) => applyFilters(state).length;

export const TimeSlotsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  errorMessage: '',
  hasMore: true,
  isDone: false,
  isError: false,
  isLoading: false,
  pageNumber: 1,
  sortBy: 'time',
  timeSlotList: [],
  summary: {
    prepaymentDisclaimer: null,
  },
});

const getTimeSlots = (state) => state.merge({
  isLoading: true,
  isDone: false,
  isError: false,
  timeSlotList: [],
  summary: {
    prepaymentDisclaimer: null,
  },
});

const getTimeSlotsDone = (state, { summary, timeSlotList }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: false,
  timeSlotList,
  summary,
  hasMore: (state.pageNumber * constants.TIME_SLOT_PAGINATION < timeSlotList.length),
});

const getTimeSlotsError = (state, { message }) => state.merge({
  isLoading: false,
  isDone: true,
  isError: true,
  errorMessage: message,
});

const getNextPage = (state) => state.merge({
  pageNumber: state.pageNumber + 1,
  hasMore: (state.pageNumber * constants.TIME_SLOT_PAGINATION < state.timeSlotList.length),
});

const setSortByTimeSlots = (state, { sortBy }) => state.merge({
  sortBy,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TIME_SLOTS]: getTimeSlots,
  [Types.GET_TIME_SLOTS_DONE]: getTimeSlotsDone,
  [Types.GET_TIME_SLOTS_ERROR]: getTimeSlotsError,
  [Types.GET_NEXT_PAGE]: getNextPage,
  [Types.SET_SORT_BY_TIME_SLOTS]: setSortByTimeSlots,
});
