import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InjectScript from 'components/shared/InjectScript';

const GoogleOneTapLogin = ({
  clientId,
  disabled,
  onError,
  onSuccess,
}) => {
  const [isGoogleClientLoaded, setIsGoogleClientLoaded] = useState(false);

  useEffect(() => {
    if (clientId && !disabled && window.google && isGoogleClientLoaded) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        prompt_parent_id: 'google_one_tap_login',
        itp_support: true,
        cancel_on_tap_outside: false,
        callback: (data) => {
          if (data?.credential) {
            fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${data.credential}`)
              .then((res) => {
                if (res?.status === 200 && res?.json) return res.json();
                if (onError) onError();
                throw new Error('Something went wrong');
              })
              .then((res) => {
                if (onSuccess) {
                  onSuccess({
                    tokenId: data.credential,
                    profileObj: {
                      googleId: res.sub,
                      email: res.email,
                    },
                    isGoogleOneTapLogin: true,
                  });
                }
              })
              .catch((error) => {
                if (onError) onError(error);
                throw error;
              });
          }
        },
      });
      window.google.accounts.id.prompt();
    }
    return () => {
      if (window?.google?.accounts) {
        window.google.accounts.id.cancel();
      }
    };
  }, [isGoogleClientLoaded, disabled, clientId, onError, onSuccess]);

  return clientId && (
    <InjectScript
      src="https://accounts.google.com/gsi/client"
      onLoad={() => { setIsGoogleClientLoaded(true); }}
    />
  );
};

GoogleOneTapLogin.defaultProps = {
  clientId: '',
  disabled: false,
  onError: (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
  },
  onSuccess: null,
};

GoogleOneTapLogin.propTypes = {
  clientId: PropTypes.string,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default GoogleOneTapLogin;
