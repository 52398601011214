import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import UsersApi from 'apis/supremeGolfApi/UsersApi';
import CourseMembershipActions, { CourseMembershipTypes } from 'reducers/courseMembership';
import CourseMembershipApi from 'apis/supremeGolfApi/CourseMembershipApi';
import { goToSignInHandler } from './session';

export function* requestCourseMembershipHandler({ courseId }) {
  try {
    const {
      course, membership, ctaUrl, ctaFaqUrl,
    } = yield call(CourseMembershipApi.getCourseMembership, courseId);
    yield put(CourseMembershipActions.getCourseMembershipDone(
      course, membership, ctaUrl, ctaFaqUrl,
    ));
  } catch (error) {
    yield put(CourseMembershipActions.getCourseMembershipError(error.message));
  }
}

export function* performMembershipUpsellSignUpHandler() {
  try {
    const { pathname, search } = yield select((state) => state.router.location);

    const goToSignIn = yield goToSignInHandler({
      path: `${pathname}${search}&isRedirectingToMembership=true`,
      newWindow: false,
      trackEvent: null,
      action: performMembershipUpsellSignUpHandler.bind(this),
    });
    if (goToSignIn) return;

    const { redirectToUrl, ctaUrl } = yield select(({ courseMembership }) => courseMembership);

    const { oneTimeToken } = yield call(UsersApi.oneTimeToken);

    const params = new URLSearchParams({
      redirectSupremeUrl: `${window.location.origin}${redirectToUrl}`,
      oneTimeToken,
    });

    yield put(CourseMembershipActions.performMembershipUpsellSignUpDone());

    window.location.href = `${ctaUrl}&${params}`;
  } catch (error) {
    yield put(CourseMembershipActions.performMembershipUpsellSignUpError(error.message));
  }
}

export function* performMembershipUpsellGoBackHandler() {
  const backToUrl = yield select(({ courseMembership }) => courseMembership.backToUrl);
  if (backToUrl) yield put(push(backToUrl));
}

function* requestCourseMembershipWatcher() {
  yield takeLatest(CourseMembershipTypes.GET_COURSE_MEMBERSHIP, requestCourseMembershipHandler);
}

function* performMembershipUpsellSignUpWatcher() {
  yield takeLatest(
    CourseMembershipTypes.PERFORM_MEMBERSHIP_UPSELL_SIGN_UP,
    performMembershipUpsellSignUpHandler,
  );
}

function* performMembershipUpsellGoBackWatcher() {
  yield takeLatest(
    CourseMembershipTypes.PERFORM_MEMBERSHIP_UPSELL_GO_BACK,
    performMembershipUpsellGoBackHandler,
  );
}

export default [
  requestCourseMembershipWatcher,
  performMembershipUpsellSignUpWatcher,
  performMembershipUpsellGoBackWatcher,
];
