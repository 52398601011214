module.exports = {
  theme: {
    colors: {
      primary: '#f56708',
      'primary-light': '#fe7b64',
      'primary-light-2': '#fde0cd',
      secondary: '#041f3b',
      'secondary-hover': '#f2f3f7',
      // Palette:
      first: '#041f3b',
      second: '#43576c',
      third: '#818f9d',
      fourth: '#041f3b',
      fifth: '#43576c',
      sixth: '#ffffff',
      seventh: '#818f9d',
      eighth: '#f56708',
      ninth: '#43576c',
      tenth: '#43576c',
      eleventh: '#43576c',
      twelfth: '#041f3b',
      thirteenth: '#818f9d',
      fourteenth: '#041f3b',
      fifteenth: '#f2f3f7',
      sixteenth: '#041f3b',
      seventeenth: '#041f3b',
      eighteenth: '#041f3b',
      nineteenth: '#c0c7ce',
      twentieth: '#041f3b',
      twentyFirst: '#041f3b',
      twentySecond: '#041f3b',
      twentyThird: '#eef1f3',
      twentyFourth: '#eef1f3',
      twentyFifth: '#feedea',
      twentySixth: '#fd4d2f',
      twentySeventh: '#1c48b4',
      twentyEighth: '#dddfea',
      twentyNinth: '#43576c',
      thirtieth: '#43576c',
      thirtyFirst: '#c0c7ce',
      thirtySecond: '#c0c7ce',
      thirtyThird: '#ffffff',
      thirtyFifth: '#f56708',
      thirtySixth: '#ffffff',
      thirtySeventh: '#fe7b64',
      thirtyEighth: '#f2f3f7',
      thirtyNinth: '#818f9d',
      fortieth: '#c0c7ce',
      fortyFirst: '#56565a',
      fortySecond: '#041f3b',
      fortyThird: 'transparent',
      fortyFourth: '#adb9c5',
      fortyFifth: '#43576c',
      fortySixth: '#f56708',
      fortySeventh: '#43576c',
      fortyEighth: '#f1f3f7',
      fortyNinth: 'transparent',
      fiftieth: '#feedea',
      fiftyFirst: '#c0c7ce',
      fiftySecond: '#43576c',
      fiftyThird: '#43576c',
      fiftyFourth: '#1d1d22',
      fiftyFifth: '#c6c6c8',
    },
    poweredBy: 'powered-by',
  },
};
