import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  welcome: [],
  welcomeLoaded: [],
  welcomeOptIn: ['preferences'],
  welcomeOptInDone: [],
});

export const WelcomeTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable.from({
  isLoading: false,
});

const welcome = (state) => state.merge({
  isLoading: true,
});

const welcomeOptIn = (state) => state.merge({
  isLoading: true,
});

const welcomeLoaded = (state) => state.merge({
  isLoading: false,
});

const welcomeOptInDone = (state) => state.merge({
  isLoading: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.WELCOME]: welcome,
  [Types.WELCOME_LOADED]: welcomeLoaded,
  [Types.WELCOME_OPT_IN]: welcomeOptIn,
  [Types.WELCOME_OPT_IN_DONE]: welcomeOptInDone,
});
