import {
  call, put, select, takeLatest, getContext,
} from 'redux-saga/effects';

import TimeSlotsActions, { TimeSlotsTypes } from 'reducers/timeSlots';
import CourseSuggestionsActions from 'reducers/courseSuggestions';
import TrackingActions from 'reducers/tracking';
import flowActions from 'reducers/flow';
import TimeSlotsApi from 'apis/supremeGolfApi/TimeSlotsApi';
import DateHelper from 'utils/dateHelper';
import { teeTimeFailure } from 'sagas/checkout';
import { UNAVAILABLE_TEE_TIMES_ERROR_CODE } from 'utils/constants';

export function* requestTimeSlotsHandler({ courseId, params }) {
  try {
    const {
      date,
      players: qty,
      holes: numHoles,
      price,
      time,
      cart: isRiding,
      rate,
      rateTypes,
      isPrepaidOnly,
      isRecommendedDate,
      networkMembershipOnly,
      slug,
    } = params;

    let minHour = null;
    let maxHour = null;
    let minPrice = null;
    let maxPrice = null;

    if (time) [minHour, maxHour] = time;
    if (price) [minPrice, maxPrice] = price;

    const searchParams = {
      courseId,
      qty,
      minHour,
      maxHour,
      minPrice,
      maxPrice,
      isRiding,
      numHoles,
      rateTypes,
      isPrepaidOnly,
      networkMembershipOnly,
    };

    if (date) searchParams.date = DateHelper.formatDate(date);
    if (rate) searchParams[rate] = true;

    const {
      summary,
      teeTimeGroups: timeSlotList,
      authToken,
    } = yield call(TimeSlotsApi.getTimeSlots, searchParams);
    yield put(flowActions.setfTeeTimes({ authToken }));
    sessionStorage.setItem('teeTimesToken', authToken);

    if (summary.availability?.error) {
      const filterValues = yield select((state) => state.search.searchParams.filterValues);
      const { previousUrl } = filterValues;
      yield* teeTimeFailure(UNAVAILABLE_TEE_TIMES_ERROR_CODE, {
        previousUrl: encodeURIComponent(previousUrl) || '/',
        courseId,
      });
      return;
    }

    const profile = yield select((state) => state.profile);

    if (!summary.availability?.forDate) {
      const detail = yield select(({ courseDetail }) => courseDetail);
      const iterable = yield getContext('iterable');
      const iterableTracking = yield call(iterable.getTrackingParams);
      const gtm = yield getContext('gtm');

      yield call(gtm.trackEvent, {
        eventCategory: 'search',
        eventAction: 'click',
        eventLabel: slug,
        event: 'feedback-landing-with-no-tee-times',
      });
      yield put(TrackingActions.trackEvent('feedback-landing-with-no-tee-times', {
        email: profile?.email,
        campaignId: iterableTracking?.campaign_id,
        templateId: iterableTracking?.template_id,
        messageId: iterableTracking?.message_id,
        userId: profile?.id,
        platform: 'web',
        course: {
          slug: detail?.course?.slug,
          name: detail?.course?.name,
          zipcode: detail?.course?.addressZipcode,
        },
      }));
    }

    if (isRecommendedDate) {
      if (summary.availability?.forDate) {
        yield put(CourseSuggestionsActions.trackCourseSuggestionEvent({
          event: 'clickOnDateRecommendationSuccess',
          eventAction: 'click',
          iterableParams: {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
          },
        }));
      } else {
        yield put(CourseSuggestionsActions.trackCourseSuggestionEvent({
          event: 'clickOnDateRecommendationFail',
          eventAction: 'click',
          iterableParams: {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
          },
        }));
      }
    }

    yield put(TimeSlotsActions.getTimeSlotsDone(summary, timeSlotList));
  } catch (error) {
    yield put(TimeSlotsActions.getTimeSlotsError(error.message));
  }
}

function* requestTimeSlotsWatcher() {
  yield takeLatest(TimeSlotsTypes.GET_TIME_SLOTS, requestTimeSlotsHandler);
}

export default [
  requestTimeSlotsWatcher,
];
