module.exports = {
  theme: {
    screens: {
      xs: '400px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1440px',
    },
    extend: {
      opacity: {
        10: '0.1',
        20: '0.2',
        30: '0.3',
        40: '0.4',
        65: '0.65',
        70: '0.7',
        80: '0.8',
        95: '0.95',
      },
      maxWidth: {
        '2-full': '200%',
        '7xl': '80rem',
        300: '75rem',
        34: '8.5rem',
        50: '12.5rem',
        68: '17rem',
        104: '26rem',
        120: '30rem',
        128: '32rem',
        134: '33.5rem',
        144: '36rem',
        196: '49rem',
        207: '51.75rem',
        250: '58rem',
        28: '7rem',
        314: '78.5rem',
        88: '22rem',
        large: '18.5rem',
        larger: '54.25rem',
        53.5: '13.375rem',
        83.5: '20.875rem',
        medium: '15.313rem',
        normal: '11rem',
        small: '8.25rem',
        smaller: '7.75rem',
      },
      minWidth: {
        16: '4rem',
        21: '5.25rem',
        23: '5.75rem',
        27: '6.75rem',
        28: '7rem',
        31: '7.75rem',
        short: '8.125rem',
        33: '8.25rem',
        37: '9.25rem',
        41: '10.188rem',
        50: '12.5rem',
        68: '17rem',
        72: '18rem',
        80: '20rem',
        88: '22rem',
        'max-content': 'max-content',
        'fit-content': 'fit-content',
      },
      transitionProperty: {
        'max-height': 'max-height',
      },
      transitionDuration: {
        500: '500ms',
        1250: '1250ms',
      },
      boxShadow: {
        'club-button': '0 2px 10px rgba(24, 69, 56, 0.3)',
        'cookies-modal': '0 6px 14px 0 rgba(0, 0, 0, 0.3)',
        'filter-button': '0 0 16px 0 rgba(30, 30, 32, 0.2)',
        'time-slot-tile': '0 4px 16px 0 rgba(30, 30, 32, 0.1)',
        membership: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
        card: '0 0 8px rgba(0, 0, 0, 0.3)',
        line: '0 1px 0 0 #ecedf4',
        normal: '0 4px 16px 0 rgba(10, 18, 69, 0.2)',
        search: '0 4px 16px 0 #ecedf4',
        slider: '0 0 0 5px rgba(251, 76, 6, 0.2)',
        'slider-black': '0 0 0 5px rgba(29, 29, 34, 0.2)',
        try: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1)',
        usual: '0 4px 16px 0 rgba(0, 0, 0, 0.1)',
        'map-pin': '0 2px 4px 0 rgba(4, 31, 59, 0.2)',
      },
      fontFamily: {
        avenir: ['Avenir', 'avenir'],
        'avenir-roman': ['AvenirRoman', 'Avenir'],
        montserrat: ['Montserrat', 'montserrat'],
        serif: ['SourceSerifPro', 'serif'],
        helvetica: ['Helvetica'],
        nunito: ['NunitoSans'],
        'roboto-condensed': ['RobotoCondensed'],
        grotesk: ['NiveauGrotesk'],
        roboto: ['Roboto'],
        gotham: ['Gotham', 'gotham', 'Avenir'],
        tungsten: ['Tungsten', 'tungsten', 'Avenir', 'avenir'],
        korolav: ['Korolav', 'korolav', 'Avenir', 'avenir'],
        poppins: ['poppins', 'Poppins', 'Avenir', 'avenir'],
        rajdhani: ['Rajdhani', 'rajdhani', 'Avenir', 'avenir'],
      },
      borderWidth: {
        tiny: '.03125rem',
      },
      borderRadius: {
        '0-5': '0.125rem',
        1: '0.25rem',
        2: '0.5rem',
        3: '.75rem',
        4: '1rem',
        larger: '1.2rem',
        8: '2rem',
      },
      height: {
        '0-5': '0.125rem',
        '2-5': '0.625rem',
        '3-5': '0.875rem',
        '5-5': '1.375rem',
        7: '1.75rem',
        '7-5': '1.875rem',
        9: '2.25rem',
        11: '2.75rem',
        13: '3.25rem',
        14: '3.5rem',
        '14-5': '3.625rem',
        15: '3.75rem',
        18: '4.5rem',
        27: '6.75rem',
        28: '7rem',
        34: '8.5rem',
        36: '9rem',
        42: '10.5rem',
        50: '12.5rem',
        54: '14.375rem',
        61: '15.25rem',
        64: '16.25rem',
        72: '19rem',
        84: '21rem',
        88: '22rem',
        91: '23.563rem',
        108: '31rem',
        160: '40rem',
        172: '43rem',
        '90vh': '90vh',
      },
      lineHeight: {
        3: 0.75,
        small: 0.83,
        '0-86': 0.86,
        '1-09': 1.09,
        usual: 1.14,
        common: 1.23,
        natural: 1.29,
        large: 1.33,
        larger: 1.44,
        largest: 1.50,
        '1-6': 1.60,
        common2: 1.66,
        relaxed2: 1.75,
        '1-85': 1.85,
        large2: 2.18,
        loose2: 2.5,
        'loose2-75': 2.75,
        'loose3-14': 3.14,
        11: '2.75rem',
        20: '5rem',
        '0-8': '0.875rem',
        '0-75': '0.75rem',
      },
      width: {
        '3-5': '0.875rem',
        '5-5': '1.375rem',
        6: '1.5rem',
        9: '2.25rem',
        11: '2.75rem',
        13: '3.25rem',
        14: '3.5rem',
        18: '4.5rem',
        27: '6.75rem',
        28: '7rem',
        34: '8.50rem',
        37: '9.25rem',
        42: '10.5rem',
        44: '11rem',
        50: '12.5rem',
        52: '13rem',
        58: '14.5rem',
        68: '17rem',
        72: '18rem',
        80: '20rem',
        88: '22rem',
        95: '23.625rem',
        96: '24rem',
        100: '25rem',
        101: '25.25rem',
        104: '26rem',
        120: '30rem',
        'fit-content': 'fit-content',
        '10p': '10%',
        '14p': '14%',
        '15p': '15p',
        '18p': '18%',
        '34p': '34%',
      },
      letterSpacing: {
        0.2: '0.2px',
        '-1-8': '-1.8px',
        '-1-2': '-1.2px',
        '-1': '-1px',
        '-0-8': '-0.8px',
        '-0-7': '-0.7px',
        '-0-65': '-0.65px',
        '-0-6': '-0.6px',
        '-0-5': '-0.5px',
        '-0-47': '-0.47px',
        '-0-44': '-0.44px',
        '-0-4': '-0.4px',
        '-0-37': '-0.37px',
        '-0-34': '-0.34px',
        '-0-33': '-0.33px',
        '-0-3': '-0.3px',
        '-0-28': '-0.28px',
        '-0-27': '-0.27px',
        '-0-26': '-0.26px',
        '-0-25': '-0.25px',
        '-0-23': '-0.23px',
        '-0-22': '-0.22px',
        '-0-21': '-0.21px',
        '-0-2': '-0.2px',
        '-0-16': '-0.16px',
        '-0-11': '-0.11px',
        '-0-04': '-0.04px',
        0: '0',
        '0-18': '0.18px',
        '0-19': '0.19px',
        '0-4': '0.4px',
        '0-6': '0.6px',
        '0-69': '0.69px',
        '0-8': '0.8px',
        1: '1px',
        2: '2px',
        '1-16': '1.16px',
        '1-5': '1.5px',
        3: '3px',
        5: '5px',
      },
      maxHeight: {
        0: '0',
        6: '1.5rem',
        limit: '1rem',
        14: '3.5rem',
        32: '8rem',
        34: '8.5rem',
        50: '13rem',
        56: '14rem',
        64: '16rem',
        100: '25rem',
      },
      minHeight: {
        4: '1rem',
        14: '3.5rem',
        15: '3.75rem',
        28: '7rem',
        32: '8rem',
        40: '10rem',
        57: '14.25rem',
        131: '32.75rem',
      },
      gap: {
        '1-5': '6px',
        '2-5': '10px',
        11: '2.75rem',
      },
      margin: {
        '-3/8': '-37.5%',
        '-7': '-1.75rem',
        '-9': '-2.25rem',
        '0-5': '0.125rem',
        '1-5': '0.375rem',
        '4-5': '1.125rem',
        7: '1.75rem',
        9: '2.25rem',
        11: '2.75rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        16: '4rem',
        17: '4.25rem',
        18: '4.5rem',
        23: '5.75rem',
        24: '6rem',
        26: '6.5rem',
        28: '7rem',
        30: '7.5rem',
        36: '9rem',
        39: '9.75rem',
        56: '14rem',
        72: '18rem',
        88: '22rem',
        auto: 'auto',
      },
      padding: {
        tiny: '0.1875rem',
        sm: '0.625rem',
        usual: '0.875rem',
        '0-5': '0.125rem',
        '1-2': '0.375rem',
        '4-5': '1.125rem',
        7: '1.75rem',
        9: '2.25rem',
        11: '2.75rem',
        13: '3.25rem',
        14: '3.5rem',
        15: '3.75rem',
        18: '4.5rem',
        22: '5.5rem',
        23: '5.75rem',
        24: '6rem',
        26: '6.5rem',
        34: '8.5rem',
      },
      inset: {
        '-14': '-3.5rem',
        '-8': '-2rem',
        '-4': '-1rem',
        '-2': '-0.50rem',
        '-1': '-0.25rem',
        1: '0.25rem',
        2: '0.5rem',
        3: '0.75rem',
        4: '1rem',
        5: '1.25rem',
        '7-8': '1.875rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        16: '4rem',
        18: '4.5rem',
        24: '6rem',
        32: '8rem',
        38: '9.75rem',
        56: '14rem',
        72: '18rem',
        88: '22rem',
        '1/2': '50%',
      },
      flex: {
        2: 2,
      },
      fontStyle: {
        oblique: 'oblique',
      },
      zIndex: {
        9: '9',
        100: '100',
      },
      translate: {
        up: [
          '-0.25rem',
        ],
      },
    },
    outline: {
      none: 'none',
    },
    colors: {
      'algae-green': '#24c46e',
      'athens-grey': '#f1f3f7',
      'foreplay-green': '#1e763f',
      beige: '#ffd591',
      black: '#000',
      'blue-facebook': '#3b5998',
      'blue-google': '#4285f4',
      'blue-grey': '#818f9d',
      'blue-purple': '#532ba8',
      'chateau-green': '#28a963',
      charcoal: '#464646',
      'clear-blue': '#2a93ff',
      'cloudy-blue': '#c0c7ce',
      'club-green-alt': '#249f5b',
      'club-green': '#2dbd6e',
      coral: '#ff4545',
      'coral-10': '#ff45451a',
      'current-color': 'currentColor',
      dark: '#1d1d22',
      'dark-two': '#1f1d37',
      'discount-green': '#0e7b5a',
      'special-red': '#da3333',
      'dark-red': '#fd4d2f',
      'dark-grey-blue': '#44586b',
      'dark-grey-three': '#1e1e20',
      'dark-grey': '#1c1c1e',
      'dodger-blue': '#1b49bd',
      'electric-blue': '#004aff',
      grey: '#9e9e9e',
      'grey-2': '#666666',
      'grey-3': '#606064',
      'grey-4': '#e5e5e5',
      'mild-grey': '#4a4a4e',
      'ice-blue': '#eef1f3',
      'light-blue-grey-two': '#bbc0d6',
      'light-gold': '#AA9056',
      'light-grey-blue-five': '#c6c6c8',
      'light-grey-blue-two': '#adafb8',
      'light-grey-blue': '#adb9c5',
      'light-grey-four': '#cccccc',
      'light-grey-three': '#c4cbd5',
      'light-grey-two': '#cccfdb',
      'light-grey': '#ced6df',
      'light-grey-2': '#f2f3f7',
      'light-grey-3': '#cccccc',
      'light-periwinkle-60': 'rgba(191, 210, 255, 0.6)',
      'light-periwinkle-three-30': 'rgba(221, 223, 234, 0.3)',
      'light-periwinkle-three': '#dddfea',
      'light-periwinkle-two': '#d6d9e6',
      'light-periwinkle': '#bfd2ff',
      'light-red': '#fedcce',
      'lighter-red': '#fff8f8',
      mango: '#ffab23',
      'membership-blue': '#4a30da',
      navy: '#041f3b',
      overlay: 'rgba(30, 30, 32, 0.7)',
      pale: '#ffeac8',
      'orangey-red': '#fd4e30',
      'pale-aqua-50': 'rgba(200, 240, 219, 0.5)',
      'pale-grey': '#ecedf4',
      'periwinkle-three': '#e4e7ea',
      'powder-blue': '#c6d1ec',
      red: '#d31f1f',
      'rewards-blue-light': '#f3fafc',
      'rewards-blue': '#85cfe3',
      salmon: '#ff7474',
      sapphire: '#1c48b4',
      secondary: '#041f3b',
      'secondary-hover': '#d6dce2',
      shamrock: '#00b252',
      silver: '#bfbfbf',
      slate: '#43576c',
      'slate-grey': '#56565a',
      transparent: 'transparent',
      'very-light-orange': '#feedea',
      white: '#fff',
      'white-one': '#f5f7f8',
      'white-two': '#f5f5f5',
      'modal-overlay': 'rgba(0, 0, 0, 0.7)',
    },
    fontSize: {
      '2-5': '0.625rem',
      3: '0.75rem',
      '3-25': '0.8125rem',
      '3-5': '0.875rem',
      '3-75': '0.9375rem',
      4: '1rem',
      '4-5': '1.125rem',
      5: '1.25rem',
      '5-5': '1.375rem',
      6: '1.5rem',
      '6-5': '1.625rem',
      7: '1.75rem',
      '7-5': '1.875rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      15: '3.75rem',
      16: '4rem',
      18: '4.5rem',
      24: '6rem',
      36: '8rem',
    },
    rotate: {
      neg: {
        45: '-45deg',
        20: '-20deg',
        5: '-5deg',
      },
      5: '5deg',
      20: '20deg',
      45: '45deg',
      90: '90deg',
      180: '180deg',
      270: '270deg',
    },
    scale: {
      80: '0.8',
      90: '0.9',
      100: '1',
      110: '1.1',
      120: '1.2',
      200: '2',
      '-100': '-1',
    },
    fill: {
      current: 'currentColor',
    },
    stroke: {
      current: 'currentColor',
    },
  },
  corePlugins: {
    animation: true,
  },
};
